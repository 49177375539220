import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Colors } from "../../../constants/styles";
import { Loader } from "../../../components";
import {
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  Snackbar,
  Alert,
  Paper,
  Autocomplete,
  TextareaAutosize,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SaveIcon from "@mui/icons-material/Save";
import { Col, Row, Form } from "react-bootstrap";

const UbahSurvey = () => {
  const { user, dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const id = location.pathname.split("/")[3];
  const [tglSurvey, setTglSurvey] = useState(new Date());
  const [noRegister, setNoRegister] = useState("");

  const [namaSurvey, setNamaSurvey] = useState("");
  const [almSurvey, setAlmSurvey] = useState("");
  const [linkMapsSurvey, setLinkMapsSurvey] = useState("");
  const [linkMapsSurvey2, setLinkMapsSurvey2] = useState("");
  const [tlpSurvey, setTlpSurvey] = useState("");
  const [noKtpSurvey, setNoKtpSurvey] = useState("");
  const [almKtpSurvey, setAlmKtpSurvey] = useState("");
  const [noKKSurvey, setNoKKSurvey] = useState("");
  const [namaIbuKandungSurvey, setNamaIbuKandungSurvey] = useState("");
  const [namaPjmSurvey, setNamaPjmSurvey] = useState("");
  const [almPjmSurvey, setAlmPjmSurvey] = useState("");
  const [tlpPjmSurvey, setTlpPjmSurvey] = useState("");
  const [hubunganSurvey, setHubunganSurvey] = useState("");
  const [noKtpPjmSurvey, setNoKtpPjmSurvey] = useState("");
  const [namaRefSurvey, setNamaRefSurvey] = useState("");
  const [almRefSurvey, setAlmRefSurvey] = useState("");
  const [tlpRefSurvey, setTlpRefSurvey] = useState("");
  const [namaRef2Survey, setNamaRef2Survey] = useState("");
  const [almRef2Survey, setAlmRef2Survey] = useState("");
  const [tlpRef2Survey, setTlpRef2Survey] = useState("");
  const [namaRef3Survey, setNamaRef3Survey] = useState("");
  const [almRef3Survey, setAlmRef3Survey] = useState("");
  const [tlpRef3Survey, setTlpRef3Survey] = useState("");
  const [kecamatanId, setKecamatanId] = useState("");
  const [pekerjaanId, setPekerjaanId] = useState("");
  const [pekerjaanPenjaminId, setPekerjaanPenjaminId] = useState("");

  // Data Format Baru
  const [tempatLahirSurvey, setTempatLahirSurvey] = useState("");
  const [tanggalLahirSurvey, setTanggalLahirSurvey] = useState(new Date());
  const [statusRegister, setStatusRegister] = useState("");
  const [statusRumahSurvey, setStatusRumahSurvey] = useState("");
  const [lamaTinggalSatuanSurvey, setLamaTinggalSatuanSurvey] = useState("");
  const [lamaTinggalSurvey, setLamaTinggalSurvey] = useState("");
  const [alamatEmailSurvey, setAlamatEmailSurvey] = useState("");
  const [lamaKerjaSatuanSurvey, setLamaKerjaSatuanSurvey] = useState("");
  const [lamaKerjaSurvey, setLamaKerjaSurvey] = useState("");
  const [pendapatanPerBulanSurvey, setPendapatanPerBulanSurvey] = useState("");
  const [pengajuanKreditSurvey, setPengajuanKreditSurvey] = useState("");
  const [pinjamanSurvey, setPinjamanSurvey] = useState("");
  const [lamaKreditSurvey, setLamaKreditSurvey] = useState("");
  const [merkSurvey, setMerkSurvey] = useState("");
  const [tipeSurvey, setTipeSurvey] = useState("");
  const [tahunSurvey, setTahunSurvey] = useState("");
  const [warnaSurvey, setWarnaSurvey] = useState("");
  const [nopolSurvey, setNopolSurvey] = useState("");
  const [keteranganSurvey, setKeteranganSurvey] = useState("");

  const [kodeSurveyor, setKodeSurveyor] = useState("");
  const [kodeSurveyorLama, setKodeSurveyorLama] = useState("");
  const [kecamatans, setKecamatans] = useState([]);
  const [pekerjaans, setPekerjaans] = useState([]);
  const [surveyors, setSurveyors] = useState([]);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [validated, setValidated] = useState(false);
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const kecamatanOptions = kecamatans.map((kecamatan) => ({
    label: `${kecamatan.kodeKecamatan} - ${kecamatan.namaKecamatan}`,
  }));

  const pekerjaanOptions = pekerjaans.map((pekerjaan) => ({
    label: `${pekerjaan.kodePekerjaan} - ${pekerjaan.namaPekerjaan}`,
  }));

  const surveyorOptions = surveyors.map((surveyor) => ({
    label: `${surveyor.kodeSurveyor} - ${surveyor.namaSurveyor}`,
  }));

  const statusOptions = [{ label: "KAWIN" }, { label: "BELUM KAWIN" }];

  const pengajuanKreditOptions = [
    { label: "KU" },
    { label: "KM" },
    { label: "KL" },
  ];

  const satuanLamaOptions = [
    { label: "HARI" },
    { label: "MINGGU" },
    { label: "BULAN" },
    { label: "TAHUN" },
  ];

  useEffect(() => {
    getSurveyById();
    getKecamatan();
    getPekerjaan();
    getSurveyor();
  }, []);

  const getSurveyById = async () => {
    if (id) {
      const response = await axios.post(`${tempUrl}/surveys/${id}`, {
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
      // Data Register/Pembeli
      setTglSurvey(formatDate(response.data.tglSurvey));
      setNoRegister(response.data.noRegister);
      setNamaSurvey(response.data.namaSurvey);
      setAlmSurvey(response.data.almSurvey);
      setLinkMapsSurvey(response.data.linkMapsSurvey);
      setLinkMapsSurvey2(response.data.linkMapsSurvey2);
      setTlpSurvey(response.data.tlpSurvey);
      setNoKtpSurvey(response.data.noKtpSurvey);
      setAlmKtpSurvey(response.data.almKtpSurvey);
      setNoKKSurvey(response.data.noKKSurvey);
      setNamaIbuKandungSurvey(response.data.namaIbuKandungSurvey);
      setNamaPjmSurvey(response.data.namaPjmSurvey);
      setAlmPjmSurvey(response.data.almPjmSurvey);
      setTlpPjmSurvey(response.data.tlpPjmSurvey);
      setHubunganSurvey(response.data.hubunganSurvey);
      setNoKtpPjmSurvey(response.data.noKtpPjmSurvey);
      setNamaRefSurvey(response.data.namaRefSurvey);
      setAlmRefSurvey(response.data.almRefSurvey);
      setTlpRefSurvey(response.data.tlpRefSurvey);
      setNamaRef2Survey(response.data.namaRef2Survey);
      setAlmRef2Survey(response.data.almRef2Survey);
      setTlpRef2Survey(response.data.tlpRef2Survey);
      setNamaRef3Survey(response.data.namaRef3Survey);
      setAlmRef3Survey(response.data.almRef3Survey);
      setTlpRef3Survey(response.data.tlpRef3Survey);
      setPekerjaanId(
        `${response.data.pekerjaanId.kodePekerjaan} - ${response.data.pekerjaanId.namaPekerjaan}`
      );
      if (response.data.pekerjaanPenjaminId) {
        setPekerjaanPenjaminId(
          `${response.data.pekerjaanPenjaminId.kodePekerjaan} - ${response.data.pekerjaanPenjaminId.namaPekerjaan}`
        );
      }
      if (response.data.kecamatanId) {
        setKecamatanId(
          `${response.data.kecamatanId.kodeKecamatan} - ${response.data.kecamatanId.namaKecamatan}`
        );
      }

      // Data Format Baru
      setTempatLahirSurvey(response.data.tempatLahirSurvey);
      setTanggalLahirSurvey(new Date(response.data.tanggalLahirSurvey));
      setStatusRegister(response.data.statusRegister);
      setStatusRumahSurvey(response.data.statusRumahSurvey);
      setLamaTinggalSatuanSurvey(response.data.lamaTinggalSatuanSurvey);
      setLamaTinggalSurvey(response.data.lamaTinggalSurvey);
      setAlamatEmailSurvey(response.data.alamatEmailSurvey);
      setLamaKerjaSatuanSurvey(response.data.lamaKerjaSatuanSurvey);
      setLamaKerjaSurvey(response.data.lamaKerjaSurvey);
      setPendapatanPerBulanSurvey(
        response.data.pendapatanPerBulanSurvey.toLocaleString("en-US")
      );
      setPengajuanKreditSurvey(response.data.pengajuanKreditSurvey);
      setPinjamanSurvey(response.data.pinjamanSurvey.toLocaleString("en-US"));
      setLamaKreditSurvey(response.data.lamaKreditSurvey);
      setMerkSurvey(response.data.merkSurvey);
      setTipeSurvey(response.data.tipeSurvey);
      setTahunSurvey(response.data.tahunSurvey);
      setWarnaSurvey(response.data.warnaSurvey);
      setNopolSurvey(response.data.nopolSurvey);
      setKeteranganSurvey(response.data.keteranganSurvey);

      setNopolSurvey(response.data.nopolSurvey);
      setKodeSurveyor(response.data.kodeSurveyor.kodeSurveyor);
      setKodeSurveyorLama(response.data.kodeSurveyor.kodeSurveyor);
    }
  };

  const getKecamatan = async () => {
    setLoading(true);
    const allKecamatans = await axios.post(`${tempUrl}/kecamatans`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setKecamatans(allKecamatans.data);
    setLoading(false);
  };

  const getPekerjaan = async () => {
    setLoading(true);
    const allPekerjaans = await axios.post(`${tempUrl}/pekerjaans`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setPekerjaans(allPekerjaans.data);
    setLoading(false);
  };

  const getSurveyor = async () => {
    setLoading(true);
    const allSurveyors = await axios.post(`${tempUrl}/surveyors`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setSurveyors(allSurveyors.data);
    setLoading(false);
  };

  const updateSurvey = async (e) => {
    e.preventDefault();
    var date = new Date();
    var current_date =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    var current_time =
      date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

    let isFailedValidation =
      noRegister.length === 0 ||
      namaSurvey.length === 0 ||
      noKtpSurvey.length === 0 ||
      almKtpSurvey.length === 0 ||
      noKKSurvey.length === 0 ||
      tempatLahirSurvey.length === 0 ||
      tanggalLahirSurvey === null ||
      statusRegister.length === 0 ||
      almSurvey.length === 0 ||
      statusRumahSurvey.length === 0 ||
      tlpSurvey.length === 0 ||
      pekerjaanId.length === 0 ||
      tlpSurvey.length === 0 ||
      statusRumahSurvey.length === 0 ||
      lamaTinggalSatuanSurvey.length === 0 ||
      lamaTinggalSurvey.length === 0 ||
      alamatEmailSurvey.length === 0 ||
      lamaKerjaSatuanSurvey.length === 0 ||
      lamaKerjaSurvey.length === 0 ||
      pendapatanPerBulanSurvey.length === 0 ||
      pengajuanKreditSurvey.length === 0 ||
      pinjamanSurvey.length === 0 ||
      lamaKreditSurvey.length === 0 ||
      merkSurvey.length === 0 ||
      tipeSurvey.length === 0 ||
      tahunSurvey.length === 0 ||
      warnaSurvey.length === 0 ||
      nopolSurvey.length === 0 ||
      kodeSurveyor.length === 0;
    if (isFailedValidation) {
      setError(true);
      setOpen(!open);
    } else {
      try {
        setLoading(true);
        let tempKecamatanId = null;
        if (kecamatanId.length !== 0) {
          const findKecamatan = await axios.post(`${tempUrl}/kecamatanByKode`, {
            kodeKecamatan: kecamatanId.split(" ", 1)[0],
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          });
          tempKecamatanId = findKecamatan.data._id;
        }
        const findPekerjaan = await axios.post(`${tempUrl}/pekerjaanByKode`, {
          kodePekerjaan: pekerjaanId.split(" ", 1)[0],
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        let tempPekerjaanPenjaminId = null;
        if (pekerjaanPenjaminId.length !== 0) {
          const findPekerjaanPenjamin = await axios.post(
            `${tempUrl}/pekerjaanByKode`,
            {
              kodePekerjaan: pekerjaanPenjaminId.split(" ", 1)[0],
              id: user._id,
              token: user.token,
              kodeCabang: user.cabang._id,
            }
          );
          tempPekerjaanPenjaminId = findPekerjaanPenjamin.data._id;
        }
        const tempSurveyor = await axios.post(`${tempUrl}/surveyorByKode`, {
          kodeSurveyor: kodeSurveyor.split(" -", 1)[0],
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        await axios.post(`${tempUrl}/updateSurvey/${id}`, {
          kodeSurveyor: tempSurveyor.data._id,
          noRegister,
          namaSurvey,
          almSurvey,
          linkMapsSurvey,
          linkMapsSurvey2,
          tlpSurvey,
          noKtpSurvey,
          almKtpSurvey,
          noKKSurvey,
          namaIbuKandungSurvey,
          namaPjmSurvey,
          almPjmSurvey,
          tlpPjmSurvey,
          hubunganSurvey,
          noKtpPjmSurvey,
          namaRefSurvey,
          almRefSurvey,
          tlpRefSurvey,
          namaRef2Survey,
          almRef2Survey,
          tlpRef2Survey,
          namaRef3Survey,
          almRef3Survey,
          tlpRef3Survey,
          kecamatanId: tempKecamatanId,
          pekerjaanId: findPekerjaan.data._id,
          pekerjaanPenjaminId: tempPekerjaanPenjaminId,
          nopolSurvey,

          // Data Format Baru
          tempatLahirSurvey,
          tanggalLahirSurvey,
          statusRegister,
          statusRumahSurvey,
          lamaTinggalSatuanSurvey,
          lamaTinggalSurvey,
          alamatEmailSurvey,
          lamaKerjaSatuanSurvey,
          lamaKerjaSurvey,
          pendapatanPerBulanSurvey: pendapatanPerBulanSurvey.replace(/,/g, ""),
          pengajuanKreditSurvey,
          pinjamanSurvey: pinjamanSurvey.replace(/,/g, ""),
          lamaKreditSurvey,
          merkSurvey,
          tipeSurvey,
          tahunSurvey,
          warnaSurvey,
          nopolSurvey,
          keteranganSurvey,

          tglInput: current_date,
          jamInput: current_time,
          userInput: user.username,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        });
        setLoading(false);
        navigate("/daftarSurvey");
      } catch (error) {
        if (error.response.status == 401) {
          dispatch({ type: "LOGOUT" });
          navigate("/");
        }
        console.log(error);
      }
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box>
      <Typography color="#757575">Penjualan</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Ubah Survey
      </Typography>
      <Divider sx={dividerStyle} />
      <Paper sx={contentContainer} elevation={12}>
        <Form noValidate validated={validated} onSubmit={updateSurvey}>
          {/* Data Customer */}
          <Paper elevation={6} sx={mainContainer}>
            <Typography variant="h5" sx={titleStyle} color="primary">
              DATA CUSTOMER
            </Typography>
            <Box sx={showDataContainer}>
              <Box sx={showDataWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Tanggal Survey :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control value={tglSurvey} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        No. Register <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={noRegister}
                          required
                          onChange={(e) =>
                            setNoRegister(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          No. Register harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Nama Survey <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={namaSurvey}
                          required
                          onChange={(e) =>
                            setNamaSurvey(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Nama harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Tempat, Tanggal Lahir <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="3">
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={tempatLahirSurvey}
                          required
                          onChange={(e) =>
                            setTempatLahirSurvey(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Tempat harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                      <Col sm="4">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          selected={tanggalLahirSurvey}
                          onChange={(e) => {
                            setTanggalLahirSurvey(e);
                          }}
                          customInput={
                            <TextField
                              error={
                                error && tanggalLahirSurvey === null && true
                              }
                              helperText={
                                error &&
                                tanggalLahirSurvey === null &&
                                "Tanggal Lahir harus diisi!"
                              }
                              sx={{ width: "100%" }}
                              size="small"
                            />
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        No. KTP <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={noKtpSurvey}
                          required
                          onChange={(e) =>
                            setNoKtpSurvey(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          No. KTP harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Alamat KTP <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={almKtpSurvey}
                          required
                          onChange={(e) =>
                            setAlmKtpSurvey(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Alamat KTP harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        No. KK <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={noKKSurvey}
                          required
                          onChange={(e) =>
                            setNoKKSurvey(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          No. KK harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Status <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="7">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={statusOptions}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              error={
                                error && statusRegister.length === 0 && true
                              }
                              helperText={
                                error &&
                                statusRegister.length === 0 &&
                                "Status harus diisi!"
                              }
                              {...params}
                            />
                          )}
                          value={{ label: statusRegister }}
                          onInputChange={(e, value) => setStatusRegister(value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Alamat Survey <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={almSurvey}
                          required
                          onChange={(e) =>
                            setAlmSurvey(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Alamat harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Status Rumah <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={statusRumahSurvey}
                          required
                          onChange={(e) =>
                            setStatusRumahSurvey(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Status Rumah harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Lama Tinggal <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="3">
                        <Form.Control
                          value={lamaTinggalSurvey}
                          required
                          onChange={(e) => {
                            let tempNum;
                            let isNumNan = isNaN(
                              parseInt(e.target.value.replace(/,/g, ""), 10)
                            );
                            if (isNumNan) {
                              tempNum = "";
                            } else {
                              tempNum = parseInt(
                                e.target.value.replace(/,/g, ""),
                                10
                              ).toLocaleString("en-US");
                            }
                            setLamaTinggalSurvey(tempNum);
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Lama Tinggal harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                      <Col sm="4">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={satuanLamaOptions}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              error={
                                error &&
                                lamaTinggalSatuanSurvey.length === 0 &&
                                true
                              }
                              helperText={
                                error &&
                                lamaTinggalSatuanSurvey.length === 0 &&
                                "Lama Tinggal Satuan harus diisi!"
                              }
                              {...params}
                            />
                          )}
                          value={{ label: lamaTinggalSatuanSurvey }}
                          onInputChange={(e, value) =>
                            setLamaTinggalSatuanSurvey(value)
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Link Maps Survey :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={linkMapsSurvey}
                          onChange={(e) => setLinkMapsSurvey(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Link Maps Survey 2 :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={linkMapsSurvey2}
                          onChange={(e) => setLinkMapsSurvey2(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Telepon Survey <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={tlpSurvey}
                          type="number"
                          required
                          onChange={(e) =>
                            setTlpSurvey(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Telepon harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Alamat Email <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={alamatEmailSurvey}
                          required
                          onChange={(e) =>
                            setAlamatEmailSurvey(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Alamat Email harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[showDataWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Pekerjaan <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="7">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={pekerjaanOptions}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              error={error && pekerjaanId.length === 0 && true}
                              helperText={
                                error &&
                                pekerjaanId.length === 0 &&
                                "Kode Pekerjaan harus diisi!"
                              }
                              {...params}
                            />
                          )}
                          value={{ label: pekerjaanId }}
                          onInputChange={(e, value) =>
                            setPekerjaanId(value.split(" ", 1)[0])
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Lama Kerja <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="3">
                        <Form.Control
                          value={lamaKerjaSurvey}
                          required
                          onChange={(e) => {
                            let tempNum;
                            let isNumNan = isNaN(
                              parseInt(e.target.value.replace(/,/g, ""), 10)
                            );
                            if (isNumNan) {
                              tempNum = "";
                            } else {
                              tempNum = parseInt(
                                e.target.value.replace(/,/g, ""),
                                10
                              ).toLocaleString("en-US");
                            }
                            setLamaKerjaSurvey(tempNum);
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Lama Kerja harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                      <Col sm="4">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={satuanLamaOptions}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              error={
                                error &&
                                lamaKerjaSatuanSurvey.length === 0 &&
                                true
                              }
                              helperText={
                                error &&
                                lamaKerjaSatuanSurvey.length === 0 &&
                                "Lama Kerja Satuan harus diisi!"
                              }
                              {...params}
                            />
                          )}
                          value={{ label: lamaKerjaSatuanSurvey }}
                          onInputChange={(e, value) =>
                            setLamaKerjaSatuanSurvey(value)
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Pendapatan/Bulan <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={pendapatanPerBulanSurvey}
                          required
                          onChange={(e) => {
                            let tempNum;
                            let isNumNan = isNaN(
                              parseInt(e.target.value.replace(/,/g, ""), 10)
                            );
                            if (isNumNan) {
                              tempNum = "";
                            } else {
                              tempNum = parseInt(
                                e.target.value.replace(/,/g, ""),
                                10
                              ).toLocaleString("en-US");
                            }
                            setPendapatanPerBulanSurvey(tempNum);
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Pendapatan/Bulan harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Pengajuan Kredit <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="7">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={pengajuanKreditOptions}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              error={
                                error &&
                                pengajuanKreditSurvey.length === 0 &&
                                true
                              }
                              helperText={
                                error &&
                                pengajuanKreditSurvey.length === 0 &&
                                "Pengajuan Kredit harus diisi!"
                              }
                              {...params}
                            />
                          )}
                          value={{ label: pengajuanKreditSurvey }}
                          onInputChange={(e, value) =>
                            setPengajuanKreditSurvey(value)
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Pinjaman <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={pinjamanSurvey}
                          required
                          onChange={(e) => {
                            let tempNum;
                            let isNumNan = isNaN(
                              parseInt(e.target.value.replace(/,/g, ""), 10)
                            );
                            if (isNumNan) {
                              tempNum = "";
                            } else {
                              tempNum = parseInt(
                                e.target.value.replace(/,/g, ""),
                                10
                              ).toLocaleString("en-US");
                            }
                            setPinjamanSurvey(tempNum);
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Pinjaman harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Lama Kredit <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="3">
                        <Form.Control
                          value={lamaKreditSurvey}
                          required
                          onChange={(e) => {
                            let tempNum;
                            let isNumNan = isNaN(
                              parseInt(e.target.value.replace(/,/g, ""), 10)
                            );
                            if (isNumNan) {
                              tempNum = "";
                            } else {
                              tempNum = parseInt(
                                e.target.value.replace(/,/g, ""),
                                10
                              ).toLocaleString("en-US");
                            }
                            setLamaKreditSurvey(tempNum);
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Lama Kredit harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                      <Col sm="4">
                        <Form.Control value={"BULAN"} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Merk <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={merkSurvey}
                          required
                          onChange={(e) =>
                            setMerkSurvey(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Merk harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Tipe <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={tipeSurvey}
                          required
                          onChange={(e) =>
                            setTipeSurvey(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Tipe harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Tahun <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={tahunSurvey}
                          required
                          onChange={(e) =>
                            setTahunSurvey(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Tahun harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Warna <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={warnaSurvey}
                          required
                          onChange={(e) =>
                            setWarnaSurvey(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Warna harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Nopol <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={nopolSurvey}
                          required
                          onChange={(e) =>
                            setNopolSurvey(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Nopol harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Keterangan :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={keteranganSurvey}
                          onChange={(e) =>
                            setKeteranganSurvey(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Keterangan harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Paper>

          {/* Data Penjamin */}
          <Paper elevation={6} sx={mainContainer}>
            <Typography variant="h5" sx={titleStyle} color="primary">
              DATA PENJAMIN
            </Typography>
            <Box sx={showDataContainer}>
              <Box sx={showDataWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Kode Surveyor <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="7">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={surveyorOptions}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              error={error && kodeSurveyor.length === 0 && true}
                              helperText={
                                error &&
                                kodeSurveyor.length === 0 &&
                                "Kode Surveyor harus diisi!"
                              }
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => setKodeSurveyor(value)}
                          inputValue={kodeSurveyor}
                          onChange={(e, value) => setKodeSurveyorLama(value)}
                          value={kodeSurveyorLama}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Nama Ibu Kandung :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={namaIbuKandungSurvey}
                          onChange={(e) =>
                            setNamaIbuKandungSurvey(
                              e.target.value.toUpperCase()
                            )
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Kode Kecamatan :
                      </Form.Label>
                      <Col sm="7">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={kecamatanOptions}
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                          value={{ label: kecamatanId }}
                          onInputChange={(e, value) => setKecamatanId(value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Nama Penjamin :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={namaPjmSurvey}
                          onChange={(e) =>
                            setNamaPjmSurvey(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Alamat Penjamin :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={almPjmSurvey}
                          onChange={(e) =>
                            setAlmPjmSurvey(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Telepon Penjamin :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          type="number"
                          value={tlpPjmSurvey}
                          onChange={(e) =>
                            setTlpPjmSurvey(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Hubungan Penjamin :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={hubunganSurvey}
                          onChange={(e) =>
                            setHubunganSurvey(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        No. KTP Penjamin :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={noKtpPjmSurvey}
                          onChange={(e) =>
                            setNoKtpPjmSurvey(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Pekerjaan Penjamin :
                      </Form.Label>
                      <Col sm="7">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={pekerjaanOptions}
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                          value={{ label: pekerjaanPenjaminId }}
                          onInputChange={(e, value) =>
                            setPekerjaanPenjaminId(value)
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[showDataWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Nama Referensi :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={namaRefSurvey}
                          onChange={(e) =>
                            setNamaRefSurvey(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Alamat Referensi :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={almRefSurvey}
                          onChange={(e) =>
                            setAlmRefSurvey(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Telepon Referensi :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={tlpRefSurvey}
                          onChange={(e) =>
                            setTlpRefSurvey(e.target.value.toUpperCase())
                          }
                          type="number"
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Nama Referensi 2 :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={namaRef2Survey}
                          onChange={(e) =>
                            setNamaRef2Survey(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Alamat Referensi 2 :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={almRef2Survey}
                          onChange={(e) =>
                            setAlmRef2Survey(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Telepon Referensi 2 :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={tlpRef2Survey}
                          onChange={(e) =>
                            setTlpRef2Survey(e.target.value.toUpperCase())
                          }
                          type="number"
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Nama Referensi 3 :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={namaRef3Survey}
                          onChange={(e) =>
                            setNamaRef3Survey(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Alamat Referensi 3 :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={almRef3Survey}
                          onChange={(e) =>
                            setAlmRef3Survey(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Telepon Referensi 3 :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={tlpRef3Survey}
                          onChange={(e) =>
                            setTlpRef3Survey(e.target.value.toUpperCase())
                          }
                          type="number"
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Paper>

          <Box sx={spacingTop}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate(`/daftarSurvey/survey/${id}`)}
              sx={{ marginRight: 2 }}
            >
              {"< Kembali"}
            </Button>
            <Button variant="contained" startIcon={<SaveIcon />} type="submit">
              Simpan
            </Button>
          </Box>
        </Form>
      </Paper>

      <Divider sx={spacingTop} />
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default UbahSurvey;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  mt: 2,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const contentContainer = {
  p: 3,
  pt: 1,
  mt: 2,
  backgroundColor: Colors.grey100,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const colorRed = {
  color: "red",
};

const mainContainer = {
  padding: 3,
  borderRadius: "20px",
  margin: {
    sm: 0,
    md: 4,
  },
  marginTop: {
    xs: 4,
    md: 0,
  },
};

const titleStyle = {
  textAlign: "center",
  fontWeight: "600",
};

import "./styles.css";
import React, { useContext, useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ScrollToTop, Footer, Sidebar } from "./components";
import "./styles.scss";
import { FaBars } from "react-icons/fa";
import { ErrorBoundary } from "react-error-boundary";
import { Fallback } from "./components/Fallback";
import { Box, CssBaseline } from "@mui/material";
import { Colors } from "./constants/styles";
import { AuthContext } from "./contexts/AuthContext";
import { useStateContext } from "./contexts/ContextProvider";
import {
  Login,
  DashboardOwner,
  DashboardCabang,
  TampilTipe,
  TambahTipe,
  UbahTipe,
  TampilWarna,
  TambahWarna,
  UbahWarna,
  TampilJenisCOA,
  TambahJenisCOA,
  UbahJenisCOA,
  TampilGroupCOA,
  TambahGroupCOA,
  UbahGroupCOA,
  TampilSubGroupCOA,
  TambahSubGroupCOA,
  UbahSubGroupCOA,
  TampilCOA,
  TambahCOA,
  UbahCOA,
  TampilWilayah,
  TambahWilayah,
  UbahWilayah,
  TampilKecamatan,
  TambahKecamatan,
  UbahKecamatan,
  TampilDealer,
  TambahDealer,
  UbahDealer,
  TampilKolektor,
  TambahKolektor,
  UbahKolektor,
  TampilDaftarMarketing,
  TampilMarketing,
  TambahMarketing,
  UbahMarketing,
  TambahJoinMarketing,
  TampilJoinMarketing,
  TampilPekerjaan,
  TambahPekerjaan,
  UbahPekerjaan,
  TampilSurveyor,
  TambahSurveyor,
  UbahSurveyor,
  TampilCabang,
  TambahCabang,
  UbahCabang,
  TampilLeasing,
  TambahLeasing,
  UbahLeasing,
  ProfilUser,
  UbahProfilUser,
  DaftarUser,
  TambahUser,
  UbahUser,
  TampilSetting,
  UbahSetting,
  TampilRegister,
  TambahRegister,
  UbahRegister,
  TampilDaftarSurvey,
  TambahSurvey,
  TampilSurvey,
  UbahSurvey,
  UbahSurveyLangsung,
  UbahFotoSurvey,
  TampilDaftarReject,
  CariSemuaReject,
  TampilReject,
  TambahReject,
  UbahReject,
  TampilRejectChild,
  TambahRejectChild,
  UbahRejectChild,
  TampilDaftarBlacklist,
  TambahBlacklist,
  TampilBlacklist,
  UbahBlacklist,
  TampilJual,
  TambahJualBekas,
  TambahJualBaru,
  UbahJualCustomer,
  UbahJualBekas,
  UbahJualBaru,
  UbahFotoJual,
  UbahStnkBpkb,
  UbahNopol,
  TampilApprove,
  UbahApprove,
  TambahApprove,
  TampilMasterCustomer,
  TampilCustomerPerSurveyor,
  TampilCustomer,
  TambahCustomer,
  UbahCustomerFotoJual,
  TampilCustomerJual,
  UbahCustomer,
  TampilCustomerAngsuran,
  TampilCustomerAngsuranChild,
  TampilTakeover,
  TambahTakeover,
  TampilSupplier,
  TambahSupplier,
  UbahSupplier,
  TampilDaftarBeli,
  TambahBeli,
  TampilBeli,
  UbahBeli,
  TambahBeliChild,
  TampilBeliChild,
  UbahBeliChild,
  TampilDaftarStok,
  UbahDaftarStok,
  CariTotalPiutang,
  CariTunggakan,
  CariNpl,
  Point6Bulan,
  SpkHarusDibuat,
  CariPenerimaanKas,
  CariPenerimaanKasBedaCabang,
  KasHarian,
  CariLapPenjualanPerTipe,
  CariLapPenjualanPerMarketing,
  CariLapPenjualanPerSurveyor,
  CariLapPenjualanPerSupplier,
  CariLapPenjualanKreditTunai,
  CariLapPenjualanKreditPerTenor,
  BiayaTarikPerSurveyor,
  JemputanPerKolektor,
  KonsumenAktifPerSurveyor,
  PenjualanPerCabang,
  RekapPenerimaan,
  PenambahanPiutang,
  PenambahanPiutangRinci,
  LabaRugiPerUnit,
  TampilDaftarAngsuran,
  TambahAngsuran,
  TampilAngsuran,
  TampilAngsuranChild,
  UbahAngsuranJual,
  TambahPercepatan,
  TampilDaftarAngsuranBedaCabang,
  TambahAngsuranBedaCabang,
  TampilAngsuranBedaCabang,
  TampilAngsuranChildBedaCabang,
  TambahPercepatanBedaCabang,
  TampilSuratPemberitahuan,
  TambahSuratPemberitahuan,
  TampilSp1,
  TambahSp1,
  TambahSp1Otomatis,
  TampilSuratPenarikan,
  TambahSuratPenarikan,
  UbahSuratPenarikan,
  TampilDaftarPemutihan,
  TampilPemutihan,
  UbahPemutihan,
  TambahPemutihan,
  TambahPemutihanChild,
  TampilPemutihanChild,
  TampilDaftarApprovePemutihan,
  TampilApprovePemutihan,
  TampilApprovePemutihanChild,
  TampilPenarikan,
  UbahPenarikan,
  TambahPenarikan,
  TampilTitip,
  TambahTitip,
  UbahTitip,
  TampilSweeping,
  TambahSweeping,
  UbahSweeping,
  TampilMotorHilang,
  TambahMotorHilang,
  UbahMotorHilang,
  KonsumenJatuhTempo,
  TampilPointKembali,
  UbahPointKembali,
  TambahPointKembali,
  TampilBiayaPerawatan,
  TambahBiayaPerawatan,
  UbahBiayaPerawatan,
  RincianBiaya,
  TampilDaftarKasMasuk,
  TambahKasMasuk,
  TampilKasMasuk,
  UbahKasMasuk,
  TambahKasMasukChild,
  TampilKasMasukChild,
  UbahKasMasukChild,
  TampilDaftarKasKeluar,
  TambahKasKeluar,
  TampilKasKeluar,
  UbahKasKeluar,
  TambahKasKeluarChild,
  TampilKasKeluarChild,
  UbahKasKeluarChild,
  TampilDaftarBankMasuk,
  TambahBankMasuk,
  TampilBankMasuk,
  UbahBankMasuk,
  TambahBankMasukChild,
  TampilBankMasukChild,
  UbahBankMasukChild,
  TampilDaftarBankKeluar,
  TambahBankKeluar,
  TampilBankKeluar,
  UbahBankKeluar,
  TambahBankKeluarChild,
  TampilBankKeluarChild,
  UbahBankKeluarChild,
  Posting,
  Unposting,
  AktivitasBukuBesar,
  NeracaSaldo,
  TutupPeriode,
  LabaRugi,
  Neraca,
  GantiPeriode,
} from "./pages/index";

import "bootstrap/dist/css/bootstrap.min.css";

const App = () => {
  const { screenSize, setScreenSize } = useStateContext();
  // const { collapseSidebar } = useProSidebar();
  const { user, dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(true);

  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);

  const handleCollapsedChange = () => {
    setCollapsed(!collapsed);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  const OWNERRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.tipeUser === "OWN") {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const MANAGERRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.tipeUser === "MGR") {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const MOTORRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.motor) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const AREARoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.area) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const BUKUBESARRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.bukuBesar) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const DEALERRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.dealer) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const KOLEKTORRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.kolektor) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const MARKETINGRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.marketing) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const PEKERJAANRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.pekerjaan) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const SURVEYORRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.surveyor) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const LEASINGRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.leasing) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const SUPPLIERRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.supplier) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const CABANGRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.cabang) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const BELIRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.beli) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const REGISTERRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.register) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const SURVEYRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.survey) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const REJECTRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.reject) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const BLACKLISTRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.blacklist) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const JUALRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.jual) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const APPROVERoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.approve) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const CUSTOMERRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.customer) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const MASTERCUSTOMERRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.masterCustomer) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const CUSTOMERPERSURVEYORRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.customerPerSurveyor) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const TAKEOVERRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.takeover) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const DAFTARSTOKRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.daftarStok) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const TOTALPIUTANGRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.totalPiutang) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const TUNGGAKANRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.tunggakan) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const NPLRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.npl) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const POINT6BULANRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.point6Bulan) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const SPKHARUSDIBUATRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.spkHarusDibuat) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const PENERIMAANKASRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.penerimaanKas) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const PENERIMAANKASBEDACABANGRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.penerimaanKasBedaCabang) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const KASHARIANRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.kasHarian) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const PENJUALANPERCABANGRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.penjualanPerCabang) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const REKAPPENERIMAANRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.rekapPenerimaan) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const PENAMBAHANPIUTANGRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.penambahanPiutang) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const LABARUGIPERUNITRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.labaRugiPerUnit) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const LAPPENJUALANRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.lapPenjualan) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const BIAYATARIKPERSURVEYORRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.biayaTarikPerSurveyor) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const JEMPUTANPERKOLEKTORRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.jemputanPerKolektor) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const KONSUMENAKTIFPERSURVEYORRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.konsumenAktifPerSurveyor) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const ANGSURANRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.angsuran) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const PERCEPATANRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.percepatan) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const ANGSURANBEDACABANGRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.angsuranBedaCabang) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const PERCEPATANBEDACABANGRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.percepatanBedaCabang) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const SPRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.sp) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const SP1Route = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.sp1) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const STRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.st) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const PEMUTIHANRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.pemutihan) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const APPROVEPEMUTIHANRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.approvePemutihan && user.tipeUser === "OWN") {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const PENARIKANRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.penarikan) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const TITIPRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.titip) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const SWEEPINGRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.sweeping) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const MOTORHILANGRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.motorHilang) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const KONSUMENJATUHTEMPORoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.konsumenJatuhTempo) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const POINTKEMBALIRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.pointKembali) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const BIAYAPERAWATANRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.biayaPerawatan) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const RINCIANBIAYARoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.rincianBiaya) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const KASMASUKRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.kasMasuk) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const KASKELUARRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.kasKeluar) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const BANKMASUKRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.bankMasuk) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const BANKKELUARRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.bankKeluar) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const POSTINGRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.posting) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const UNPOSTINGRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.unposting) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const AKTIVITASBUKUBESARRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.aktivitasBukuBesar) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const LABARUGIRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.labaRugi) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const NERACARoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.neraca) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const NERACASALDORoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.neracaSaldo) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const TUTUPPERIODERoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.tutupPeriode && user.tipeUser !== "ADM") {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const GANTIPERIODERoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.gantiPeriode) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const PROFILUSERRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (user.akses.profilUser) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const SETTINGRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (
      user.akses.setting &&
      user.tipeUser !== "ADM" &&
      user.tipeUser !== "FIN"
    ) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const DAFTARUSERRoute = ({ children }) => {
    const { user, dispatch } = useContext(AuthContext);

    if (
      user.akses.daftarUser &&
      user.tipeUser !== "ADM" &&
      user.tipeUser !== "FIN"
    ) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const openMenuFunction = () => {
    setOpen(!open);
    // collapseSidebar();
  };

  const contentWrapper = {
    minHeight: "100vh",
    //width: open && screenSize >= 650 ? "80vw" : "100vw"
  };

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Box>
      <CssBaseline />
      {/* <Header /> */}
      <div className={`app ${toggled ? "toggled" : ""}`}>
        {user && (
          <Sidebar
            collapsed={collapsed}
            toggled={toggled}
            handleToggleSidebar={handleToggleSidebar}
            handleCollapsedChange={handleCollapsedChange}
          />
        )}
        <main>
          {user && (
            <div
              className="btn-toggle"
              onClick={() => handleToggleSidebar(true)}
            >
              <FaBars />
            </div>
          )}
          <Box sx={contentWrapper}>
            <ErrorBoundary FallbackComponent={Fallback}>
              <ScrollToTop />
              <Routes>
                {user ? (
                  <Route path="/" />
                ) : (
                  <Route path="/" element={<Login />} />
                )}
                <Route path="/admin" />
                <Route path="/login" element={<Login />} />
                <Route path="/unauthorized" element={<Login />} />
                {/* Dashboard */}
                <Route
                  path="/dashboardOwner"
                  element={
                    <OWNERRoute>
                      <DashboardOwner />
                    </OWNERRoute>
                  }
                />
                <Route
                  path="/dashboardCabang"
                  element={
                    <MANAGERRoute>
                      <DashboardCabang />
                    </MANAGERRoute>
                  }
                />
                {/* Tipe */}
                <Route
                  path="/tipe"
                  element={
                    <MOTORRoute>
                      <TampilTipe />
                    </MOTORRoute>
                  }
                />
                <Route
                  path="/tipe/tambahTipe"
                  element={
                    <MOTORRoute>
                      <TambahTipe />
                    </MOTORRoute>
                  }
                />
                <Route
                  path="/tipe/:id"
                  element={
                    <MOTORRoute>
                      <TampilTipe />
                    </MOTORRoute>
                  }
                />
                <Route
                  path="/tipe/:id/edit"
                  element={
                    <MOTORRoute>
                      <UbahTipe />
                    </MOTORRoute>
                  }
                />
                {/* Warna */}
                <Route
                  path="/warna"
                  element={
                    <MOTORRoute>
                      <TampilWarna />
                    </MOTORRoute>
                  }
                />
                <Route
                  path="/warna/:id"
                  element={
                    <MOTORRoute>
                      <TampilWarna />
                    </MOTORRoute>
                  }
                />
                <Route
                  path="/warna/tambahWarna"
                  element={
                    <MOTORRoute>
                      <TambahWarna />
                    </MOTORRoute>
                  }
                />
                <Route
                  path="/warna/:id/edit"
                  element={
                    <MOTORRoute>
                      <UbahWarna />
                    </MOTORRoute>
                  }
                />
                {/* Wilayah */}
                <Route
                  path="/wilayah"
                  element={
                    <AREARoute>
                      <TampilWilayah />
                    </AREARoute>
                  }
                />
                <Route
                  path="/wilayah/:id"
                  element={
                    <AREARoute>
                      <TampilWilayah />
                    </AREARoute>
                  }
                />
                <Route
                  path="/wilayah/tambahWilayah"
                  element={
                    <AREARoute>
                      <TambahWilayah />
                    </AREARoute>
                  }
                />
                <Route
                  path="/wilayah/:id/edit"
                  element={
                    <AREARoute>
                      <UbahWilayah />
                    </AREARoute>
                  }
                />
                {/* Kecamatan */}
                <Route
                  path="/kecamatan"
                  element={
                    <AREARoute>
                      <TampilKecamatan />
                    </AREARoute>
                  }
                />
                <Route
                  path="/kecamatan/:id"
                  element={
                    <AREARoute>
                      <TampilKecamatan />
                    </AREARoute>
                  }
                />
                <Route
                  path="/kecamatan/tambahKecamatan"
                  element={
                    <AREARoute>
                      <TambahKecamatan />
                    </AREARoute>
                  }
                />
                <Route
                  path="/kecamatan/:id/edit"
                  element={
                    <AREARoute>
                      <UbahKecamatan />
                    </AREARoute>
                  }
                />
                {/* Jenis COA */}
                <Route
                  path="/jenisCOA"
                  element={
                    <AREARoute>
                      <TampilJenisCOA />
                    </AREARoute>
                  }
                />
                <Route
                  path="/jenisCOA/:id"
                  element={
                    <AREARoute>
                      <TampilJenisCOA />
                    </AREARoute>
                  }
                />
                <Route
                  path="/jenisCOA/tambahJenisCOA"
                  element={
                    <AREARoute>
                      <TambahJenisCOA />
                    </AREARoute>
                  }
                />
                <Route
                  path="/jenisCOA/:id/edit"
                  element={
                    <AREARoute>
                      <UbahJenisCOA />
                    </AREARoute>
                  }
                />
                {/* Group COA */}
                <Route
                  path="/groupCOA"
                  element={
                    <BUKUBESARRoute>
                      <TampilGroupCOA />
                    </BUKUBESARRoute>
                  }
                />
                <Route
                  path="/groupCOA/:id"
                  element={
                    <BUKUBESARRoute>
                      <TampilGroupCOA />
                    </BUKUBESARRoute>
                  }
                />
                <Route
                  path="/groupCOA/tambahGroupCOA"
                  element={
                    <BUKUBESARRoute>
                      <TambahGroupCOA />
                    </BUKUBESARRoute>
                  }
                />
                <Route
                  path="/groupCOA/:id/edit"
                  element={
                    <BUKUBESARRoute>
                      <UbahGroupCOA />
                    </BUKUBESARRoute>
                  }
                />
                {/* Sub Group COA */}
                <Route
                  path="/subGroupCOA"
                  element={
                    <BUKUBESARRoute>
                      <TampilSubGroupCOA />
                    </BUKUBESARRoute>
                  }
                />
                <Route
                  path="/subGroupCOA/:id"
                  element={
                    <BUKUBESARRoute>
                      <TampilSubGroupCOA />
                    </BUKUBESARRoute>
                  }
                />
                <Route
                  path="/subGroupCOA/tambahSubGroupCOA"
                  element={
                    <BUKUBESARRoute>
                      <TambahSubGroupCOA />
                    </BUKUBESARRoute>
                  }
                />
                <Route
                  path="/subGroupCOA/:id/edit"
                  element={
                    <BUKUBESARRoute>
                      <UbahSubGroupCOA />
                    </BUKUBESARRoute>
                  }
                />
                {/* COA */}
                <Route
                  path="/COA"
                  element={
                    <BUKUBESARRoute>
                      <TampilCOA />
                    </BUKUBESARRoute>
                  }
                />
                <Route
                  path="/COA/:id"
                  element={
                    <BUKUBESARRoute>
                      <TampilCOA />
                    </BUKUBESARRoute>
                  }
                />
                <Route
                  path="/COA/tambahCOA"
                  element={
                    <BUKUBESARRoute>
                      <TambahCOA />
                    </BUKUBESARRoute>
                  }
                />
                <Route
                  path="/COA/:id/edit"
                  element={
                    <BUKUBESARRoute>
                      <UbahCOA />
                    </BUKUBESARRoute>
                  }
                />
                {/* Dealer */}
                <Route
                  path="/dealer"
                  element={
                    <DEALERRoute>
                      <TampilDealer />
                    </DEALERRoute>
                  }
                />
                <Route
                  path="/dealer/:id"
                  element={
                    <DEALERRoute>
                      <TampilDealer />
                    </DEALERRoute>
                  }
                />
                <Route
                  path="/dealer/tambahDealer"
                  element={
                    <DEALERRoute>
                      <TambahDealer />
                    </DEALERRoute>
                  }
                />
                <Route
                  path="/dealer/:id/edit"
                  element={
                    <DEALERRoute>
                      <UbahDealer />
                    </DEALERRoute>
                  }
                />
                {/* Kolektor */}
                <Route
                  path="/kolektor"
                  element={
                    <KOLEKTORRoute>
                      <TampilKolektor />
                    </KOLEKTORRoute>
                  }
                />
                <Route
                  path="/kolektor/:id"
                  element={
                    <KOLEKTORRoute>
                      <TampilKolektor />
                    </KOLEKTORRoute>
                  }
                />
                <Route
                  path="/kolektor/tambahKolektor"
                  element={
                    <KOLEKTORRoute>
                      <TambahKolektor />
                    </KOLEKTORRoute>
                  }
                />
                <Route
                  path="/kolektor/:id/edit"
                  element={
                    <KOLEKTORRoute>
                      <UbahKolektor />
                    </KOLEKTORRoute>
                  }
                />
                {/* Marketing */}
                <Route
                  path="/marketing"
                  element={
                    <MARKETINGRoute>
                      <TampilDaftarMarketing />
                    </MARKETINGRoute>
                  }
                />
                <Route
                  path="/marketing/:id"
                  element={
                    <MARKETINGRoute>
                      <TampilMarketing />
                    </MARKETINGRoute>
                  }
                />
                <Route
                  path="/marketing/tambahMarketing"
                  element={
                    <MARKETINGRoute>
                      <TambahMarketing />
                    </MARKETINGRoute>
                  }
                />
                <Route
                  path="/marketing/:id/edit"
                  element={
                    <MARKETINGRoute>
                      <UbahMarketing />
                    </MARKETINGRoute>
                  }
                />
                <Route
                  path="/marketing/:id/tambahMarketingJoin"
                  element={
                    <MARKETINGRoute>
                      <TambahJoinMarketing />
                    </MARKETINGRoute>
                  }
                />
                <Route
                  path="/marketing/:id/:idJoinMarketing"
                  element={
                    <MARKETINGRoute>
                      <TampilJoinMarketing />
                    </MARKETINGRoute>
                  }
                />
                {/* Pekerjaan */}
                <Route
                  path="/pekerjaan"
                  element={
                    <PEKERJAANRoute>
                      <TampilPekerjaan />
                    </PEKERJAANRoute>
                  }
                />
                <Route
                  path="/pekerjaan/:id"
                  element={
                    <PEKERJAANRoute>
                      <TampilPekerjaan />
                    </PEKERJAANRoute>
                  }
                />
                <Route
                  path="/pekerjaan/tambahPekerjaan"
                  element={
                    <PEKERJAANRoute>
                      <TambahPekerjaan />
                    </PEKERJAANRoute>
                  }
                />
                <Route
                  path="/pekerjaan/:id/edit"
                  element={
                    <PEKERJAANRoute>
                      <UbahPekerjaan />
                    </PEKERJAANRoute>
                  }
                />
                {/* Surveyor */}
                <Route
                  path="/surveyor"
                  element={
                    <SURVEYORRoute>
                      <TampilSurveyor />
                    </SURVEYORRoute>
                  }
                />
                <Route
                  path="/surveyor/:id"
                  element={
                    <SURVEYORRoute>
                      <TampilSurveyor />
                    </SURVEYORRoute>
                  }
                />
                <Route
                  path="/surveyor/tambahSurveyor"
                  element={
                    <SURVEYORRoute>
                      <TambahSurveyor />
                    </SURVEYORRoute>
                  }
                />
                <Route
                  path="/surveyor/:id/edit"
                  element={
                    <SURVEYORRoute>
                      <UbahSurveyor />
                    </SURVEYORRoute>
                  }
                />
                {/* Leasing */}
                <Route
                  path="/leasing"
                  element={
                    <LEASINGRoute>
                      <TampilLeasing />
                    </LEASINGRoute>
                  }
                />
                <Route
                  path="/leasing/:id"
                  element={
                    <LEASINGRoute>
                      <TampilLeasing />
                    </LEASINGRoute>
                  }
                />
                <Route
                  path="/leasing/tambahLeasing"
                  element={
                    <LEASINGRoute>
                      <TambahLeasing />
                    </LEASINGRoute>
                  }
                />
                <Route
                  path="/leasing/:id/edit"
                  element={
                    <LEASINGRoute>
                      <UbahLeasing />
                    </LEASINGRoute>
                  }
                />
                {/* Supplier */}
                <Route
                  path="/supplier"
                  element={
                    <SUPPLIERRoute>
                      <TampilSupplier />
                    </SUPPLIERRoute>
                  }
                />
                <Route
                  path="/supplier/:id"
                  element={
                    <SUPPLIERRoute>
                      <TampilSupplier />
                    </SUPPLIERRoute>
                  }
                />
                <Route
                  path="/supplier/tambahSupplier"
                  element={
                    <SUPPLIERRoute>
                      <TambahSupplier />
                    </SUPPLIERRoute>
                  }
                />
                <Route
                  path="/supplier/:id/edit"
                  element={
                    <SUPPLIERRoute>
                      <UbahSupplier />
                    </SUPPLIERRoute>
                  }
                />
                {/* Cabang */}
                <Route
                  path="/cabang"
                  element={
                    <CABANGRoute>
                      <TampilCabang />
                    </CABANGRoute>
                  }
                />
                <Route
                  path="/cabang/:id"
                  element={
                    <CABANGRoute>
                      <TampilCabang />
                    </CABANGRoute>
                  }
                />
                <Route
                  path="/cabang/tambahCabang"
                  element={
                    <CABANGRoute>
                      <TambahCabang />
                    </CABANGRoute>
                  }
                />
                <Route
                  path="/cabang/:id/edit"
                  element={
                    <CABANGRoute>
                      <UbahCabang />
                    </CABANGRoute>
                  }
                />
                {/* PEMBELIAN */}
                {/* Beli */}
                <Route
                  path="/daftarBeli"
                  element={
                    <BELIRoute>
                      <TampilDaftarBeli />
                    </BELIRoute>
                  }
                />
                <Route
                  path="/daftarBeli/beli/tambahBeli"
                  element={
                    <BELIRoute>
                      <TambahBeli />
                    </BELIRoute>
                  }
                />
                <Route
                  path="/daftarBeli/beli/:id"
                  element={
                    <BELIRoute>
                      <TampilBeli />
                    </BELIRoute>
                  }
                />
                <Route
                  path="/daftarBeli/beli/:id/edit"
                  element={
                    <BELIRoute>
                      <UbahBeli />
                    </BELIRoute>
                  }
                />
                {/* A Beli */}
                <Route
                  path="/daftarBeli/beli/:id/tambahBeliChild"
                  element={
                    <BELIRoute>
                      <TambahBeliChild />
                    </BELIRoute>
                  }
                />
                <Route
                  path="/daftarBeli/beli/:id/:idBeliChild"
                  element={
                    <BELIRoute>
                      <TampilBeliChild />
                    </BELIRoute>
                  }
                />
                <Route
                  path="/daftarBeli/beli/:id/:idBeliChild/edit"
                  element={
                    <BELIRoute>
                      <UbahBeliChild />
                    </BELIRoute>
                  }
                />
                {/* PENJUALAN */}
                {/* Register */}
                <Route
                  path="/register"
                  element={
                    <REGISTERRoute>
                      <TampilRegister />
                    </REGISTERRoute>
                  }
                />
                <Route
                  path="/register/:id"
                  element={
                    <REGISTERRoute>
                      <TampilRegister />
                    </REGISTERRoute>
                  }
                />
                <Route
                  path="/register/tambahRegister"
                  element={
                    <REGISTERRoute>
                      <TambahRegister />
                    </REGISTERRoute>
                  }
                />
                <Route
                  path="/register/:id/edit"
                  element={
                    <REGISTERRoute>
                      <UbahRegister />
                    </REGISTERRoute>
                  }
                />
                {/* Survey */}
                <Route
                  path="/daftarSurvey"
                  element={
                    <SURVEYRoute>
                      <TampilDaftarSurvey />
                    </SURVEYRoute>
                  }
                />
                <Route
                  path="/daftarSurvey/survey/tambahSurvey"
                  element={
                    <SURVEYRoute>
                      <TambahSurvey />
                    </SURVEYRoute>
                  }
                />
                <Route
                  path="/daftarSurvey/survey/:id"
                  element={
                    <SURVEYRoute>
                      <TampilSurvey />
                    </SURVEYRoute>
                  }
                />
                <Route
                  path="/daftarSurvey/survey/:id/edit"
                  element={
                    <SURVEYRoute>
                      <UbahSurvey />
                    </SURVEYRoute>
                  }
                />
                <Route
                  path="/daftarSurvey/survey/:id/editLangsung"
                  element={
                    <SURVEYRoute>
                      <UbahSurveyLangsung />
                    </SURVEYRoute>
                  }
                />
                <Route
                  path="/daftarSurvey/survey/:id/editFoto"
                  element={
                    <SURVEYRoute>
                      <UbahFotoSurvey />
                    </SURVEYRoute>
                  }
                />
                {/* Reject */}
                <Route
                  path="/daftarReject"
                  element={
                    <REJECTRoute>
                      <TampilDaftarReject />
                    </REJECTRoute>
                  }
                />
                <Route
                  path="/daftarReject/cariReject"
                  element={
                    <REJECTRoute>
                      <CariSemuaReject />
                    </REJECTRoute>
                  }
                />
                <Route
                  path="/daftarReject/reject/tambahReject"
                  element={
                    <REJECTRoute>
                      <TambahReject />
                    </REJECTRoute>
                  }
                />
                <Route
                  path="/daftarReject/reject/:id"
                  element={
                    <REJECTRoute>
                      <TampilReject />
                    </REJECTRoute>
                  }
                />
                <Route
                  path="/daftarReject/reject/:id/edit"
                  element={
                    <REJECTRoute>
                      <UbahReject />
                    </REJECTRoute>
                  }
                />
                {/* Reject Child */}
                <Route
                  path="/daftarReject/reject/:id/tambahRejectChild"
                  element={
                    <REJECTRoute>
                      <TambahRejectChild />
                    </REJECTRoute>
                  }
                />
                <Route
                  path="/daftarReject/reject/:id/:idRejectChild"
                  element={
                    <REJECTRoute>
                      <TampilRejectChild />
                    </REJECTRoute>
                  }
                />
                <Route
                  path="/daftarReject/reject/:id/:idRejectChild/edit"
                  element={
                    <REJECTRoute>
                      <UbahRejectChild />
                    </REJECTRoute>
                  }
                />
                {/* Blacklist */}
                <Route
                  path="/daftarBlacklist"
                  element={
                    <BLACKLISTRoute>
                      <TampilDaftarBlacklist />
                    </BLACKLISTRoute>
                  }
                />
                <Route
                  path="/daftarBlacklist/blacklist/tambahBlacklist"
                  element={
                    <BLACKLISTRoute>
                      <TambahBlacklist />
                    </BLACKLISTRoute>
                  }
                />
                <Route
                  path="/daftarBlacklist/blacklist/:id"
                  element={
                    <BLACKLISTRoute>
                      <TampilBlacklist />
                    </BLACKLISTRoute>
                  }
                />
                <Route
                  path="/daftarBlacklist/blacklist/:id/edit"
                  element={
                    <BLACKLISTRoute>
                      <UbahBlacklist />
                    </BLACKLISTRoute>
                  }
                />
                {/* Jual */}
                <Route
                  path="/jual"
                  element={
                    <JUALRoute>
                      <TampilJual />
                    </JUALRoute>
                  }
                />
                <Route
                  path="/jual/:id"
                  element={
                    <JUALRoute>
                      <TampilJual />
                    </JUALRoute>
                  }
                />
                <Route
                  path="/jual/tambahJualBekas"
                  element={
                    <JUALRoute>
                      <TambahJualBekas />
                    </JUALRoute>
                  }
                />
                <Route
                  path="/jual/tambahJualBaru"
                  element={
                    <JUALRoute>
                      <TambahJualBaru />
                    </JUALRoute>
                  }
                />
                <Route
                  path="/jual/:id/editCustomer"
                  element={
                    <JUALRoute>
                      <UbahJualCustomer />
                    </JUALRoute>
                  }
                />
                <Route
                  path="/jual/:id/editBekas"
                  element={
                    <JUALRoute>
                      <UbahJualBekas />
                    </JUALRoute>
                  }
                />
                <Route
                  path="/jual/:id/editBaru"
                  element={
                    <JUALRoute>
                      <UbahJualBaru />
                    </JUALRoute>
                  }
                />
                <Route
                  path="/jual/:id/editFoto"
                  element={
                    <JUALRoute>
                      <UbahFotoJual />
                    </JUALRoute>
                  }
                />
                <Route
                  path="/jual/:id/editStnkBpkb"
                  element={
                    <JUALRoute>
                      <UbahStnkBpkb />
                    </JUALRoute>
                  }
                />
                <Route
                  path="/jual/:id/editNopol"
                  element={
                    <JUALRoute>
                      <UbahNopol />
                    </JUALRoute>
                  }
                />
                {/* Approve */}
                <Route
                  path="/approve"
                  element={
                    <APPROVERoute>
                      <TampilApprove />
                    </APPROVERoute>
                  }
                />
                <Route
                  path="/approve/:id"
                  element={
                    <APPROVERoute>
                      <TampilApprove />
                    </APPROVERoute>
                  }
                />
                <Route
                  path="/approve/:id/edit"
                  element={
                    <APPROVERoute>
                      <UbahApprove />
                    </APPROVERoute>
                  }
                />
                <Route
                  path="/approve/tambah"
                  element={
                    <APPROVERoute>
                      <TambahApprove />
                    </APPROVERoute>
                  }
                />
                {/* Master Customer */}
                <Route
                  path="/masterCustomer"
                  element={
                    <MASTERCUSTOMERRoute>
                      <TampilMasterCustomer />
                    </MASTERCUSTOMERRoute>
                  }
                />
                <Route
                  path="/masterCustomer/:id"
                  element={
                    <MASTERCUSTOMERRoute>
                      <TampilMasterCustomer />
                    </MASTERCUSTOMERRoute>
                  }
                />
                {/* Customer Per Surveyor */}
                <Route
                  path="/customerPerSurveyor"
                  element={
                    <CUSTOMERPERSURVEYORRoute>
                      <TampilCustomerPerSurveyor />
                    </CUSTOMERPERSURVEYORRoute>
                  }
                />
                <Route
                  path="/customerPerSurveyor/:id"
                  element={
                    <CUSTOMERPERSURVEYORRoute>
                      <TampilCustomerPerSurveyor />
                    </CUSTOMERPERSURVEYORRoute>
                  }
                />
                {/* Customer */}
                <Route
                  path="/customer"
                  element={
                    <CUSTOMERRoute>
                      <TampilCustomer />
                    </CUSTOMERRoute>
                  }
                />
                <Route
                  path="/customer/tambahCustomer"
                  element={
                    <CUSTOMERRoute>
                      <TambahCustomer />
                    </CUSTOMERRoute>
                  }
                />
                <Route
                  path="/customer/:id"
                  element={
                    <CUSTOMERRoute>
                      <TampilCustomerJual />
                    </CUSTOMERRoute>
                  }
                />
                <Route
                  path="/customer/:id/edit"
                  element={
                    <CUSTOMERRoute>
                      <UbahCustomer />
                    </CUSTOMERRoute>
                  }
                />
                <Route
                  path="/customer/:id/:idJual"
                  element={
                    <CUSTOMERRoute>
                      <TampilCustomerAngsuran />
                    </CUSTOMERRoute>
                  }
                />
                <Route
                  path="/customer/:id/:idJual/edit"
                  element={
                    <CUSTOMERRoute>
                      <UbahCustomerFotoJual />
                    </CUSTOMERRoute>
                  }
                />
                <Route
                  path="/customer/:id/:idJual/:idAngsuranChild"
                  element={
                    <CUSTOMERRoute>
                      <TampilCustomerAngsuranChild />
                    </CUSTOMERRoute>
                  }
                />
                {/* Takeover */}
                <Route
                  path="/takeover"
                  element={
                    <TAKEOVERRoute>
                      <TampilTakeover />
                    </TAKEOVERRoute>
                  }
                />
                <Route
                  path="/takeover/:id"
                  element={
                    <TAKEOVERRoute>
                      <TampilTakeover />
                    </TAKEOVERRoute>
                  }
                />
                <Route
                  path="/takeover/tambahTakeover"
                  element={
                    <TAKEOVERRoute>
                      <TambahTakeover />
                    </TAKEOVERRoute>
                  }
                />
                {/* Daftar Stok */}
                <Route
                  path="/daftarStok"
                  element={
                    <DAFTARSTOKRoute>
                      <TampilDaftarStok />
                    </DAFTARSTOKRoute>
                  }
                />
                <Route
                  path="/daftarStok/:id"
                  element={
                    <DAFTARSTOKRoute>
                      <TampilDaftarStok />
                    </DAFTARSTOKRoute>
                  }
                />
                <Route
                  path="/daftarStok/:id/edit"
                  element={
                    <DAFTARSTOKRoute>
                      <UbahDaftarStok />
                    </DAFTARSTOKRoute>
                  }
                />
                {/* Total Piutang */}
                <Route
                  path="/totalPiutang"
                  element={
                    <TOTALPIUTANGRoute>
                      <CariTotalPiutang />
                    </TOTALPIUTANGRoute>
                  }
                />
                {/* Tunggakan */}
                <Route
                  path="/tunggakan"
                  element={
                    <TUNGGAKANRoute>
                      <CariTunggakan />
                    </TUNGGAKANRoute>
                  }
                />
                {/* NPL */}
                <Route
                  path="/npl"
                  element={
                    <NPLRoute>
                      <CariNpl />
                    </NPLRoute>
                  }
                />
                {/* Point 6 Bulan */}
                <Route
                  path="/point6Bulan"
                  element={
                    <POINT6BULANRoute>
                      <Point6Bulan />
                    </POINT6BULANRoute>
                  }
                />
                {/* Spk Harus Dibuat */}
                <Route
                  path="/spkHarusDibuat"
                  element={
                    <SPKHARUSDIBUATRoute>
                      <SpkHarusDibuat />
                    </SPKHARUSDIBUATRoute>
                  }
                />
                {/* Penerimaan Kas */}
                <Route
                  path="/penerimaanKas"
                  element={
                    <PENERIMAANKASRoute>
                      <CariPenerimaanKas />
                    </PENERIMAANKASRoute>
                  }
                />
                <Route
                  path="/penerimaanKasBedaCabang"
                  element={
                    <PENERIMAANKASBEDACABANGRoute>
                      <CariPenerimaanKasBedaCabang />
                    </PENERIMAANKASBEDACABANGRoute>
                  }
                />
                {/* Kas Harian */}
                <Route
                  path="/kasHarian"
                  element={
                    <KASHARIANRoute>
                      <KasHarian />
                    </KASHARIANRoute>
                  }
                />
                {/* Laporan Penjualan Per Cabang */}
                <Route
                  path="/penjualanPerCabang"
                  element={
                    <LAPPENJUALANRoute>
                      <PenjualanPerCabang />
                    </LAPPENJUALANRoute>
                  }
                />
                {/* Laporan Rekap Penerimaan */}
                <Route
                  path="/rekapPenerimaan"
                  element={
                    <REKAPPENERIMAANRoute>
                      <RekapPenerimaan />
                    </REKAPPENERIMAANRoute>
                  }
                />
                {/* Laporan Penambahan Piutang */}
                <Route
                  path="/penambahanPiutang"
                  element={
                    <PENAMBAHANPIUTANGRoute>
                      <PenambahanPiutang />
                    </PENAMBAHANPIUTANGRoute>
                  }
                />
                <Route
                  path="/penambahanPiutangRinci"
                  element={
                    <PENAMBAHANPIUTANGRoute>
                      <PenambahanPiutangRinci />
                    </PENAMBAHANPIUTANGRoute>
                  }
                />
                {/* Laporan Laba Rugi Per Unit */}
                <Route
                  path="/labaRugiPerUnit"
                  element={
                    <LABARUGIPERUNITRoute>
                      <LabaRugiPerUnit />
                    </LABARUGIPERUNITRoute>
                  }
                />
                {/* Laporan Penjualan Per Tipe */}
                <Route
                  path="/lapPenjualanPerTipe"
                  element={
                    <LAPPENJUALANRoute>
                      <CariLapPenjualanPerTipe />
                    </LAPPENJUALANRoute>
                  }
                />
                {/* Laporan Penjualan Per Marketing */}
                <Route
                  path="/lapPenjualanPerMarketing"
                  element={
                    <LAPPENJUALANRoute>
                      <CariLapPenjualanPerMarketing />
                    </LAPPENJUALANRoute>
                  }
                />
                {/* Laporan Penjualan Per Surveyor */}
                <Route
                  path="/lapPenjualanPerSurveyor"
                  element={
                    <LAPPENJUALANRoute>
                      <CariLapPenjualanPerSurveyor />
                    </LAPPENJUALANRoute>
                  }
                />
                {/* Laporan Penjualan Per Surveyor */}
                <Route
                  path="/lapPenjualanPerSupplier"
                  element={
                    <LAPPENJUALANRoute>
                      <CariLapPenjualanPerSupplier />
                    </LAPPENJUALANRoute>
                  }
                />
                {/* Laporan Penjualan Kredit Tunai */}
                <Route
                  path="/lapPenjualanKreditTunai"
                  element={
                    <LAPPENJUALANRoute>
                      <CariLapPenjualanKreditTunai />
                    </LAPPENJUALANRoute>
                  }
                />
                {/* Laporan Penjualan Kredit Per Tenor */}
                <Route
                  path="/lapPenjualanKreditPerTenor"
                  element={
                    <LAPPENJUALANRoute>
                      <CariLapPenjualanKreditPerTenor />
                    </LAPPENJUALANRoute>
                  }
                />
                {/* Laporan Biaya Tarik Per Surveyor */}
                <Route
                  path="/biayaTarikPerSurveyor"
                  element={
                    <BIAYATARIKPERSURVEYORRoute>
                      <BiayaTarikPerSurveyor />
                    </BIAYATARIKPERSURVEYORRoute>
                  }
                />
                {/* Laporan Jemputan Per Kolektor */}
                <Route
                  path="/jemputanPerKolektor"
                  element={
                    <JEMPUTANPERKOLEKTORRoute>
                      <JemputanPerKolektor />
                    </JEMPUTANPERKOLEKTORRoute>
                  }
                />
                {/* Laporan Konsumen Aktif Per Surveyor */}
                <Route
                  path="/konsumenAktifPerSurveyor"
                  element={
                    <KONSUMENAKTIFPERSURVEYORRoute>
                      <KonsumenAktifPerSurveyor />
                    </KONSUMENAKTIFPERSURVEYORRoute>
                  }
                />
                {/* Angsuran */}
                <Route
                  path="/daftarAngsuran"
                  element={
                    <ANGSURANRoute>
                      <TampilDaftarAngsuran />
                    </ANGSURANRoute>
                  }
                />
                <Route
                  path="/daftarAngsuran/angsuran/tambahAngsuran"
                  element={
                    <ANGSURANRoute>
                      <TambahAngsuran />
                    </ANGSURANRoute>
                  }
                />
                <Route
                  path="/daftarAngsuran/angsuran/:id"
                  element={
                    <ANGSURANRoute>
                      <TampilAngsuran />
                    </ANGSURANRoute>
                  }
                />
                <Route
                  path="/daftarAngsuran/angsuran/:id/:idAngsuranChild"
                  element={
                    <ANGSURANRoute>
                      <TampilAngsuranChild />
                    </ANGSURANRoute>
                  }
                />
                <Route
                  path="/daftarAngsuran/angsuran/:id/ubahSurveyor/:idJual"
                  element={
                    <ANGSURANRoute>
                      <UbahAngsuranJual />
                    </ANGSURANRoute>
                  }
                />
                {/* Percepatan */}
                <Route
                  path="/tambahPercepatan"
                  element={
                    <PERCEPATANRoute>
                      <TambahPercepatan />
                    </PERCEPATANRoute>
                  }
                />
                {/* Angsuran Beda Cabang */}
                <Route
                  path="/daftarAngsuranBedaCabang"
                  element={
                    <ANGSURANBEDACABANGRoute>
                      <TampilDaftarAngsuranBedaCabang />
                    </ANGSURANBEDACABANGRoute>
                  }
                />
                <Route
                  path="/daftarAngsuranBedaCabang/angsuranBedaCabang/tambahAngsuranBedaCabang"
                  element={
                    <ANGSURANBEDACABANGRoute>
                      <TambahAngsuranBedaCabang />
                    </ANGSURANBEDACABANGRoute>
                  }
                />
                <Route
                  path="/daftarAngsuranBedaCabang/angsuranBedaCabang/:id/:cabangAngsuran"
                  element={
                    <ANGSURANBEDACABANGRoute>
                      <TampilAngsuranBedaCabang />
                    </ANGSURANBEDACABANGRoute>
                  }
                />
                <Route
                  path="/daftarAngsuranBedaCabang/angsuranBedaCabang/:id/:cabangAngsuran/:idAngsuranChild"
                  element={
                    <ANGSURANBEDACABANGRoute>
                      <TampilAngsuranChildBedaCabang />
                    </ANGSURANBEDACABANGRoute>
                  }
                />
                {/* Percepatan Beda Cabang */}
                <Route
                  path="/tambahPercepatanBedaCabang"
                  element={
                    <PERCEPATANBEDACABANGRoute>
                      <TambahPercepatanBedaCabang />
                    </PERCEPATANBEDACABANGRoute>
                  }
                />
                {/* Surat Pemberitahuan */}
                <Route
                  path="/suratPemberitahuan"
                  element={
                    <SPRoute>
                      <TampilSuratPemberitahuan />
                    </SPRoute>
                  }
                />
                <Route
                  path="/suratPemberitahuan/tambahSuratPemberitahuan"
                  element={
                    <SPRoute>
                      <TambahSuratPemberitahuan />
                    </SPRoute>
                  }
                />
                <Route
                  path="/suratPemberitahuan/:id"
                  element={
                    <SPRoute>
                      <TampilSuratPemberitahuan />
                    </SPRoute>
                  }
                />
                {/* Sp1 */}
                <Route
                  path="/sp1"
                  element={
                    <SP1Route>
                      <TampilSp1 />
                    </SP1Route>
                  }
                />
                <Route
                  path="/sp1/tambahSp1"
                  element={
                    <SP1Route>
                      <TambahSp1 />
                    </SP1Route>
                  }
                />
                <Route
                  path="/sp1/tambahSp1Otomatis"
                  element={
                    <SP1Route>
                      <TambahSp1Otomatis />
                    </SP1Route>
                  }
                />
                <Route
                  path="/sp1/:id"
                  element={
                    <SP1Route>
                      <TampilSp1 />
                    </SP1Route>
                  }
                />
                {/* Surat Penarikan */}
                <Route
                  path="/suratPenarikan"
                  element={
                    <STRoute>
                      <TampilSuratPenarikan />
                    </STRoute>
                  }
                />
                <Route
                  path="/suratPenarikan/tambahSuratPenarikan"
                  element={
                    <STRoute>
                      <TambahSuratPenarikan />
                    </STRoute>
                  }
                />
                <Route
                  path="/suratPenarikan/:id"
                  element={
                    <STRoute>
                      <TampilSuratPenarikan />
                    </STRoute>
                  }
                />
                <Route
                  path="/suratPenarikan/:id/edit"
                  element={
                    <STRoute>
                      <UbahSuratPenarikan />
                    </STRoute>
                  }
                />
                {/* Pemutihan */}
                <Route
                  path="/daftarPemutihan"
                  element={
                    <PEMUTIHANRoute>
                      <TampilDaftarPemutihan />
                    </PEMUTIHANRoute>
                  }
                />
                <Route
                  path="/daftarPemutihan/pemutihan/:id/edit"
                  element={
                    <PEMUTIHANRoute>
                      <UbahPemutihan />
                    </PEMUTIHANRoute>
                  }
                />
                <Route
                  path="/pemutihan/tambahPemutihan"
                  element={
                    <PEMUTIHANRoute>
                      <TambahPemutihan />
                    </PEMUTIHANRoute>
                  }
                />
                <Route
                  path="/daftarPemutihan/pemutihan/:id"
                  element={
                    <PEMUTIHANRoute>
                      <TampilPemutihan />
                    </PEMUTIHANRoute>
                  }
                />
                <Route
                  path="/daftarPemutihan/pemutihan/:id/tambahPemutihan"
                  element={
                    <PEMUTIHANRoute>
                      <TambahPemutihanChild />
                    </PEMUTIHANRoute>
                  }
                />
                <Route
                  path="/daftarPemutihan/pemutihan/:id/:idPemutihan"
                  element={
                    <PEMUTIHANRoute>
                      <TampilPemutihanChild />
                    </PEMUTIHANRoute>
                  }
                />
                {/* Approve Pemutihan */}
                <Route
                  path="/daftarApprovePemutihan"
                  element={
                    <APPROVEPEMUTIHANRoute>
                      <TampilDaftarApprovePemutihan />
                    </APPROVEPEMUTIHANRoute>
                  }
                />
                <Route
                  path="/daftarApprovePemutihan/approvePemutihan/:id"
                  element={
                    <APPROVEPEMUTIHANRoute>
                      <TampilApprovePemutihan />
                    </APPROVEPEMUTIHANRoute>
                  }
                />
                <Route
                  path="/daftarApprovePemutihan/approvePemutihan/:id/:idApprovePemutihan"
                  element={
                    <APPROVEPEMUTIHANRoute>
                      <TampilApprovePemutihanChild />
                    </APPROVEPEMUTIHANRoute>
                  }
                />
                {/* Penarikan */}
                <Route
                  path="/penarikan"
                  element={
                    <PENARIKANRoute>
                      <TampilPenarikan />
                    </PENARIKANRoute>
                  }
                />
                <Route
                  path="/penarikan/:id"
                  element={
                    <PENARIKANRoute>
                      <TampilPenarikan />
                    </PENARIKANRoute>
                  }
                />
                <Route
                  path="/penarikan/:id/edit"
                  element={
                    <PENARIKANRoute>
                      <UbahPenarikan />
                    </PENARIKANRoute>
                  }
                />
                <Route
                  path="/penarikan/tambahPenarikan"
                  element={
                    <PENARIKANRoute>
                      <TambahPenarikan />
                    </PENARIKANRoute>
                  }
                />
                {/* Titip */}
                <Route
                  path="/titip"
                  element={
                    <TITIPRoute>
                      <TampilTitip />
                    </TITIPRoute>
                  }
                />
                <Route
                  path="/titip/tambahTitip"
                  element={
                    <TITIPRoute>
                      <TambahTitip />
                    </TITIPRoute>
                  }
                />
                <Route
                  path="/titip/:id"
                  element={
                    <TITIPRoute>
                      <TampilTitip />
                    </TITIPRoute>
                  }
                />
                <Route
                  path="/titip/:id/edit"
                  element={
                    <TITIPRoute>
                      <UbahTitip />
                    </TITIPRoute>
                  }
                />
                {/* Sweeping */}
                <Route
                  path="/sweeping"
                  element={
                    <SWEEPINGRoute>
                      <TampilSweeping />
                    </SWEEPINGRoute>
                  }
                />
                <Route
                  path="/sweeping/tambahSweeping"
                  element={
                    <SWEEPINGRoute>
                      <TambahSweeping />
                    </SWEEPINGRoute>
                  }
                />
                <Route
                  path="/sweeping/:id"
                  element={
                    <SWEEPINGRoute>
                      <TampilSweeping />
                    </SWEEPINGRoute>
                  }
                />
                <Route
                  path="/sweeping/:id/edit"
                  element={
                    <SWEEPINGRoute>
                      <UbahSweeping />
                    </SWEEPINGRoute>
                  }
                />
                {/* Motor Hilang */}
                <Route
                  path="/motorHilang"
                  element={
                    <MOTORHILANGRoute>
                      <TampilMotorHilang />
                    </MOTORHILANGRoute>
                  }
                />
                <Route
                  path="/motorHilang/tambahMotorHilang"
                  element={
                    <MOTORHILANGRoute>
                      <TambahMotorHilang />
                    </MOTORHILANGRoute>
                  }
                />
                <Route
                  path="/motorHilang/:id"
                  element={
                    <MOTORHILANGRoute>
                      <TampilMotorHilang />
                    </MOTORHILANGRoute>
                  }
                />
                <Route
                  path="/motorHilang/:id/edit"
                  element={
                    <MOTORHILANGRoute>
                      <UbahMotorHilang />
                    </MOTORHILANGRoute>
                  }
                />
                {/* Konsumen Jatuh Tempo */}
                <Route
                  path="/konsumenJatuhTempo"
                  element={
                    <KONSUMENJATUHTEMPORoute>
                      <KonsumenJatuhTempo />
                    </KONSUMENJATUHTEMPORoute>
                  }
                />
                {/* PointKembali */}
                <Route
                  path="/pointKembali"
                  element={
                    <POINTKEMBALIRoute>
                      <TampilPointKembali />
                    </POINTKEMBALIRoute>
                  }
                />
                <Route
                  path="/pointKembali/:id"
                  element={
                    <POINTKEMBALIRoute>
                      <TampilPointKembali />
                    </POINTKEMBALIRoute>
                  }
                />
                <Route
                  path="/pointKembali/:id/edit"
                  element={
                    <POINTKEMBALIRoute>
                      <UbahPointKembali />
                    </POINTKEMBALIRoute>
                  }
                />
                <Route
                  path="/pointKembali/tambahPointKembali"
                  element={
                    <POINTKEMBALIRoute>
                      <TambahPointKembali />
                    </POINTKEMBALIRoute>
                  }
                />
                {/* Biaya Perawatan */}
                <Route
                  path="/biayaPerawatan"
                  element={
                    <BIAYAPERAWATANRoute>
                      <TampilBiayaPerawatan />
                    </BIAYAPERAWATANRoute>
                  }
                />
                <Route
                  path="/biayaPerawatan/:id"
                  element={
                    <BIAYAPERAWATANRoute>
                      <TampilBiayaPerawatan />
                    </BIAYAPERAWATANRoute>
                  }
                />
                <Route
                  path="/biayaPerawatan/:id/edit"
                  element={
                    <BIAYAPERAWATANRoute>
                      <UbahBiayaPerawatan />
                    </BIAYAPERAWATANRoute>
                  }
                />
                <Route
                  path="/biayaPerawatan/tambahBiayaPerawatan"
                  element={
                    <BIAYAPERAWATANRoute>
                      <TambahBiayaPerawatan />
                    </BIAYAPERAWATANRoute>
                  }
                />
                {/* Rincian Biaya */}
                <Route
                  path="/rincianBiaya"
                  element={
                    <RINCIANBIAYARoute>
                      <RincianBiaya />
                    </RINCIANBIAYARoute>
                  }
                />
                {/* Kas Masuk */}
                <Route
                  path="/daftarKasMasuk"
                  element={
                    <KASMASUKRoute>
                      <TampilDaftarKasMasuk />
                    </KASMASUKRoute>
                  }
                />
                <Route
                  path="/daftarKasMasuk/kasMasuk/tambahKasMasuk"
                  element={
                    <KASMASUKRoute>
                      <TambahKasMasuk />
                    </KASMASUKRoute>
                  }
                />
                <Route
                  path="/daftarKasMasuk/kasMasuk/:id"
                  element={
                    <KASMASUKRoute>
                      <TampilKasMasuk />
                    </KASMASUKRoute>
                  }
                />
                <Route
                  path="/daftarKasMasuk/kasMasuk/:id/edit"
                  element={
                    <KASMASUKRoute>
                      <UbahKasMasuk />
                    </KASMASUKRoute>
                  }
                />
                {/* Kas Masuk Child */}
                <Route
                  path="/daftarKasMasuk/kasMasuk/:id/tambahKasMasukChild"
                  element={
                    <KASMASUKRoute>
                      <TambahKasMasukChild />
                    </KASMASUKRoute>
                  }
                />
                <Route
                  path="/daftarKasMasuk/kasMasuk/:id/:idKasMasukChild"
                  element={
                    <KASMASUKRoute>
                      <TampilKasMasukChild />
                    </KASMASUKRoute>
                  }
                />
                <Route
                  path="/daftarKasMasuk/kasMasuk/:id/:idKasMasukChild/edit"
                  element={
                    <KASMASUKRoute>
                      <UbahKasMasukChild />
                    </KASMASUKRoute>
                  }
                />
                {/* Kas Keluar */}
                <Route
                  path="/daftarKasKeluar"
                  element={
                    <KASKELUARRoute>
                      <TampilDaftarKasKeluar />
                    </KASKELUARRoute>
                  }
                />
                <Route
                  path="/daftarKasKeluar/kasKeluar/tambahKasKeluar"
                  element={
                    <KASKELUARRoute>
                      <TambahKasKeluar />
                    </KASKELUARRoute>
                  }
                />
                <Route
                  path="/daftarKasKeluar/kasKeluar/:id"
                  element={
                    <KASKELUARRoute>
                      <TampilKasKeluar />
                    </KASKELUARRoute>
                  }
                />
                <Route
                  path="/daftarKasKeluar/kasKeluar/:id/edit"
                  element={
                    <KASKELUARRoute>
                      <UbahKasKeluar />
                    </KASKELUARRoute>
                  }
                />
                {/* Kas Keluar Child */}
                <Route
                  path="/daftarKasKeluar/kasKeluar/:id/tambahKasKeluarChild"
                  element={
                    <KASKELUARRoute>
                      <TambahKasKeluarChild />
                    </KASKELUARRoute>
                  }
                />
                <Route
                  path="/daftarKasKeluar/kasKeluar/:id/:idKasKeluarChild"
                  element={
                    <KASKELUARRoute>
                      <TampilKasKeluarChild />
                    </KASKELUARRoute>
                  }
                />
                <Route
                  path="/daftarKasKeluar/kasKeluar/:id/:idKasKeluarChild/edit"
                  element={
                    <KASKELUARRoute>
                      <UbahKasKeluarChild />
                    </KASKELUARRoute>
                  }
                />
                {/* Bank masuk */}
                <Route
                  path="/daftarBankMasuk"
                  element={
                    <BANKMASUKRoute>
                      <TampilDaftarBankMasuk />
                    </BANKMASUKRoute>
                  }
                />
                <Route
                  path="/daftarBankMasuk/bankMasuk/tambahBankMasuk"
                  element={
                    <BANKMASUKRoute>
                      <TambahBankMasuk />
                    </BANKMASUKRoute>
                  }
                />
                <Route
                  path="/daftarBankMasuk/bankMasuk/:id"
                  element={
                    <BANKMASUKRoute>
                      <TampilBankMasuk />
                    </BANKMASUKRoute>
                  }
                />
                <Route
                  path="/daftarBankMasuk/bankMasuk/:id/edit"
                  element={
                    <BANKMASUKRoute>
                      <UbahBankMasuk />
                    </BANKMASUKRoute>
                  }
                />
                {/* Bank Masuk Child */}
                <Route
                  path="/daftarBankMasuk/bankMasuk/:id/tambahBankMasukChild"
                  element={
                    <BANKMASUKRoute>
                      <TambahBankMasukChild />
                    </BANKMASUKRoute>
                  }
                />
                <Route
                  path="/daftarBankMasuk/bankMasuk/:id/:idBankMasukChild"
                  element={
                    <BANKMASUKRoute>
                      <TampilBankMasukChild />
                    </BANKMASUKRoute>
                  }
                />
                <Route
                  path="/daftarBankMasuk/bankMasuk/:id/:idBankMasukChild/edit"
                  element={
                    <BANKMASUKRoute>
                      <UbahBankMasukChild />
                    </BANKMASUKRoute>
                  }
                />
                {/* Bank Keluar */}
                <Route
                  path="/daftarBankKeluar"
                  element={
                    <BANKKELUARRoute>
                      <TampilDaftarBankKeluar />
                    </BANKKELUARRoute>
                  }
                />
                <Route
                  path="/daftarBankKeluar/bankKeluar/tambahBankKeluar"
                  element={
                    <BANKKELUARRoute>
                      <TambahBankKeluar />
                    </BANKKELUARRoute>
                  }
                />
                <Route
                  path="/daftarBankKeluar/bankKeluar/:id"
                  element={
                    <BANKKELUARRoute>
                      <TampilBankKeluar />
                    </BANKKELUARRoute>
                  }
                />
                <Route
                  path="/daftarBankKeluar/bankKeluar/:id/edit"
                  element={
                    <BANKKELUARRoute>
                      <UbahBankKeluar />
                    </BANKKELUARRoute>
                  }
                />
                {/* Bank Keluar Child */}
                <Route
                  path="/daftarBankKeluar/bankKeluar/:id/tambahBankKeluarChild"
                  element={
                    <BANKKELUARRoute>
                      <TambahBankKeluarChild />
                    </BANKKELUARRoute>
                  }
                />
                <Route
                  path="/daftarBankKeluar/bankKeluar/:id/:idBankKeluarChild"
                  element={
                    <BANKKELUARRoute>
                      <TampilBankKeluarChild />
                    </BANKKELUARRoute>
                  }
                />
                <Route
                  path="/daftarBankKeluar/bankKeluar/:id/:idBankKeluarChild/edit"
                  element={
                    <BANKKELUARRoute>
                      <UbahBankKeluarChild />
                    </BANKKELUARRoute>
                  }
                />
                {/* Posting */}
                <Route
                  path="/posting"
                  element={
                    <POSTINGRoute>
                      <Posting />
                    </POSTINGRoute>
                  }
                />
                {/* Unposting */}
                <Route
                  path="/unposting"
                  element={
                    <UNPOSTINGRoute>
                      <Unposting />
                    </UNPOSTINGRoute>
                  }
                />
                {/* Aktivitas Buku Besar */}
                <Route
                  path="/aktivitasBukuBesar"
                  element={
                    <AKTIVITASBUKUBESARRoute>
                      <AktivitasBukuBesar />
                    </AKTIVITASBUKUBESARRoute>
                  }
                />
                {/* Neraca Saldo */}
                <Route
                  path="/neracaSaldo"
                  element={
                    <NERACASALDORoute>
                      <NeracaSaldo />
                    </NERACASALDORoute>
                  }
                />
                {/* Tutup Periode */}
                <Route
                  path="/tutupPeriode"
                  element={
                    <TUTUPPERIODERoute>
                      <TutupPeriode />
                    </TUTUPPERIODERoute>
                  }
                />
                {/* Ganti Periode */}
                <Route
                  path="/gantiPeriode"
                  element={
                    <GANTIPERIODERoute>
                      <GantiPeriode />
                    </GANTIPERIODERoute>
                  }
                />
                {/* Ganti Periode */}
                <Route
                  path="/gantiPeriode/:id"
                  element={
                    <GANTIPERIODERoute>
                      <GantiPeriode />
                    </GANTIPERIODERoute>
                  }
                />
                {/* Laba Rugi */}
                <Route
                  path="/labaRugi"
                  element={
                    <LABARUGIRoute>
                      <LabaRugi />
                    </LABARUGIRoute>
                  }
                />
                {/* Neraca */}
                <Route
                  path="/neraca"
                  element={
                    <NERACARoute>
                      <Neraca />
                    </NERACARoute>
                  }
                />
                {/* Profil User */}
                <Route
                  path="/profilUser"
                  element={
                    <PROFILUSERRoute>
                      <ProfilUser />
                    </PROFILUSERRoute>
                  }
                />
                <Route
                  path="/profilUser/:id/edit"
                  element={
                    <PROFILUSERRoute>
                      <UbahProfilUser />
                    </PROFILUSERRoute>
                  }
                />
                {/* Daftar User */}
                <Route
                  path="/daftarUser"
                  element={
                    <DAFTARUSERRoute>
                      <DaftarUser />
                    </DAFTARUSERRoute>
                  }
                />
                <Route
                  path="/daftarUser/:id"
                  element={
                    <DAFTARUSERRoute>
                      <DaftarUser />
                    </DAFTARUSERRoute>
                  }
                />
                <Route
                  path="/daftarUser/:id/edit"
                  element={
                    <DAFTARUSERRoute>
                      <UbahUser />
                    </DAFTARUSERRoute>
                  }
                />
                <Route
                  path="/daftarUser/tambahUser"
                  element={
                    <DAFTARUSERRoute>
                      <TambahUser />
                    </DAFTARUSERRoute>
                  }
                />
                {/* Profil User */}
                <Route
                  path="/setting"
                  element={
                    <SETTINGRoute>
                      <TampilSetting />
                    </SETTINGRoute>
                  }
                />
                <Route
                  path="/setting/:id/edit"
                  element={
                    <SETTINGRoute>
                      <UbahSetting />
                    </SETTINGRoute>
                  }
                />
                <Route path="*" element={<p>Halaman tidak ditemukan!</p>} />
              </Routes>
            </ErrorBoundary>
          </Box>
          <Footer />
        </main>
      </div>
    </Box>
  );
};

export default App;

import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Colors } from "../../../constants/styles";
import { Loader } from "../../../components";
import {
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  Snackbar,
  Alert,
  Paper,
  Autocomplete,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SaveIcon from "@mui/icons-material/Save";
import { Col, Row, Form } from "react-bootstrap";

const TambahRegister = () => {
  const { user } = useContext(AuthContext);
  const { dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [kodeRegister, setKodeRegister] = useState("");
  const [namaRegister, setNamaRegister] = useState("");
  const [almRegister, setAlmRegister] = useState("");
  const [linkMapsRegister, setLinkMapsRegister] = useState("");
  const [tlpRegister, setTlpRegister] = useState("");
  const [noKtpRegister, setNoKtpRegister] = useState("");
  const [almKtpRegister, setAlmKtpRegister] = useState("");
  const [noKKRegister, setNoKKRegister] = useState("");
  const [namaIbuKandungRegister, setNamaIbuKandungRegister] = useState("");
  const [namaPjmRegister, setNamaPjmRegister] = useState("");
  const [almPjmRegister, setAlmPjmRegister] = useState("");
  const [tlpPjmRegister, setTlpPjmRegister] = useState("");
  const [hubunganRegister, setHubunganRegister] = useState("");
  const [noKtpPjmRegister, setNoKtpPjmRegister] = useState("");
  const [namaRefRegister, setNamaRefRegister] = useState("");
  const [almRefRegister, setAlmRefRegister] = useState("");
  const [tlpRefRegister, setTlpRefRegister] = useState("");
  const [kecamatanId, setKecamatanId] = useState("");
  const [pekerjaanId, setPekerjaanId] = useState("");
  const [pekerjaanPenjaminId, setPekerjaanPenjaminId] = useState("");

  // Data Format Baru
  const [tempatLahirRegister, setTempatLahirRegister] = useState("");
  const [tanggalLahirRegister, setTanggalLahirRegister] = useState(new Date());
  const [statusRegister, setStatusRegister] = useState("");
  const [statusRumahRegister, setStatusRumahRegister] = useState("");
  const [lamaTinggalSatuanRegister, setLamaTinggalSatuanRegister] =
    useState("");
  const [lamaTinggalRegister, setLamaTinggalRegister] = useState("");
  const [alamatEmailRegister, setAlamatEmailRegister] = useState("");
  const [lamaKerjaSatuanRegister, setLamaKerjaSatuanRegister] = useState("");
  const [lamaKerjaRegister, setLamaKerjaRegister] = useState("");
  const [pendapatanPerBulanRegister, setPendapatanPerBulanRegister] =
    useState("");
  const [pengajuanKreditRegister, setPengajuanKreditRegister] = useState("");
  const [pinjamanRegister, setPinjamanRegister] = useState("");
  const [lamaKreditRegister, setLamaKreditRegister] = useState("");
  const [merkRegister, setMerkRegister] = useState("");
  const [tipeRegister, setTipeRegister] = useState("");
  const [tahunRegister, setTahunRegister] = useState("");
  const [warnaRegister, setWarnaRegister] = useState("");
  const [nopolRegister, setNopolRegister] = useState("");
  const [keteranganRegister, setKeteranganRegister] = useState("");

  const [kecamatans, setKecamatans] = useState([]);
  const [pekerjaans, setPekerjaans] = useState([]);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [validated, setValidated] = useState(false);
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const kecamatanOptions = kecamatans.map((kecamatan) => ({
    label: `${kecamatan.kodeKecamatan} - ${kecamatan.namaKecamatan}`,
  }));

  const pekerjaanOptions = pekerjaans.map((pekerjaan) => ({
    label: `${pekerjaan.kodePekerjaan} - ${pekerjaan.namaPekerjaan}`,
  }));

  const statusOptions = [{ label: "KAWIN" }, { label: "BELUM KAWIN" }];

  const pengajuanKreditOptions = [
    { label: "KU" },
    { label: "KM" },
    { label: "KL" },
  ];

  const satuanLamaOptions = [
    { label: "HARI" },
    { label: "MINGGU" },
    { label: "BULAN" },
    { label: "TAHUN" },
  ];

  useEffect(() => {
    getNextKodeRegister();
    getKecamatan();
    getPekerjaan();
  }, []);

  const getNextKodeRegister = async () => {
    setLoading(true);
    const nextKodeRegister = await axios.post(`${tempUrl}/registersNextKode`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setKodeRegister(nextKodeRegister.data);
    setLoading(false);
  };

  const getKecamatan = async () => {
    setLoading(true);
    const allKecamatans = await axios.post(`${tempUrl}/kecamatans`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setKecamatans(allKecamatans.data);
    setLoading(false);
  };

  const getPekerjaan = async () => {
    setLoading(true);
    const allPekerjaans = await axios.post(`${tempUrl}/pekerjaans`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setPekerjaans(allPekerjaans.data);
    setPekerjaanPenjaminId(
      `${allPekerjaans.data[0].kodePekerjaan} - ${allPekerjaans.data[0].namaPekerjaan}`
    );
    setLoading(false);
  };

  const saveRegister = async (e) => {
    e.preventDefault();
    var date = new Date();
    var current_date =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    var current_time =
      date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

    const findReject = await axios.post(`${tempUrl}/rejectByNoKTPReject`, {
      noKTPReject: noKtpRegister,
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });

    if (findReject.data) {
      if (
        window.confirm(
          `No. KTP ${noKtpRegister} sudah di Reject! Apakah lanjut menyimpan?`
        ) == true
      ) {
      } else {
        return;
      }
    }

    const findBlacklist = await axios.post(
      `${tempUrl}/blacklistByNoKTPBlacklist`,
      {
        noKTPBlacklist: noKtpRegister,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      }
    );

    if (findBlacklist.data) {
      alert(`No. KTP ${noKtpRegister} sudah di Blacklist!`);
      return;
    }

    let isFailedValidation =
      namaRegister.length === 0 ||
      noKtpRegister.length === 0 ||
      almKtpRegister.length === 0 ||
      noKKRegister.length === 0 ||
      tempatLahirRegister.length === 0 ||
      tanggalLahirRegister === null ||
      statusRegister.length === 0 ||
      almRegister.length === 0 ||
      statusRumahRegister.length === 0 ||
      tlpRegister.length === 0 ||
      pekerjaanId.length === 0 ||
      tlpRegister.length === 0 ||
      statusRumahRegister.length === 0 ||
      lamaTinggalSatuanRegister.length === 0 ||
      lamaTinggalRegister.length === 0 ||
      alamatEmailRegister.length === 0 ||
      lamaKerjaSatuanRegister.length === 0 ||
      lamaKerjaRegister.length === 0 ||
      pendapatanPerBulanRegister.length === 0 ||
      pengajuanKreditRegister.length === 0 ||
      pinjamanRegister.length === 0 ||
      lamaKreditRegister.length === 0 ||
      merkRegister.length === 0 ||
      tipeRegister.length === 0 ||
      tahunRegister.length === 0 ||
      warnaRegister.length === 0 ||
      nopolRegister.length === 0;
    if (isFailedValidation) {
      setError(true);
      setOpen(!open);
    } else {
      try {
        setLoading(true);
        // const findKecamatan = await axios.post(`${tempUrl}/kecamatanByKode`, {
        //   kodeKecamatan: kecamatanId,
        //   id: user._id,
        //   token: user.token,
        //   kodeCabang: user.cabang._id,
        // });
        const findPekerjaan = await axios.post(`${tempUrl}/pekerjaanByKode`, {
          kodePekerjaan: pekerjaanId,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        // const findPekerjaanPenjamin = await axios.post(
        //   `${tempUrl}/pekerjaanByKode`,
        //   {
        //     kodePekerjaan: pekerjaanPenjaminId,
        //     id: user._id,
        //     token: user.token,
        //     kodeCabang: user.cabang._id,
        //   }
        // );
        // const allPekerjaans = await axios.post(`${tempUrl}/pekerjaans`, {
        //   id: user._id,
        //   token: user.token,
        // });
        await axios.post(`${tempUrl}/saveRegister`, {
          // namaRegister,
          // almRegister,
          // linkMapsRegister,
          // tlpRegister,
          // noKtpRegister,
          // almKtpRegister,
          // noKKRegister,
          // namaIbuKandungRegister,
          // namaPjmRegister,
          // almPjmRegister,
          // tlpPjmRegister,
          // hubunganRegister,
          // noKtpPjmRegister,
          // namaRefRegister,
          // almRefRegister,
          // tlpRefRegister,
          // kecamatanId: findKecamatan.data._id,
          // pekerjaanId: findPekerjaan.data._id,
          // pekerjaanPenjaminId: findPekerjaanPenjamin.data
          //   ? findPekerjaanPenjamin.data._id
          //   : allPekerjaans.data[0]._id,

          namaRegister,
          noKtpRegister,
          almKtpRegister,
          noKKRegister,
          almRegister,
          linkMapsRegister,
          tlpRegister,
          pekerjaanId: findPekerjaan.data._id,
          tlpRegister,
          isJual: false,
          isReject: false,

          // Data Format Baru
          tempatLahirRegister,
          tanggalLahirRegister,
          statusRegister,
          statusRumahRegister,
          lamaTinggalSatuanRegister,
          lamaTinggalRegister,
          alamatEmailRegister,
          lamaKerjaSatuanRegister,
          lamaKerjaRegister,
          pendapatanPerBulanRegister: pendapatanPerBulanRegister.replace(
            /,/g,
            ""
          ),
          pengajuanKreditRegister,
          pinjamanRegister: pinjamanRegister.replace(/,/g, ""),
          lamaKreditRegister,
          merkRegister,
          tipeRegister,
          tahunRegister,
          warnaRegister,
          nopolRegister,
          keteranganRegister,

          tglInput: current_date,
          jamInput: current_time,
          userInput: user.username,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        });
        setLoading(false);
        navigate("/register");
      } catch (error) {
        if (error.response.status == 401) {
          dispatch({ type: "LOGOUT" });
          navigate("/");
        }
        console.log(error);
      }
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box>
      <Typography color="#757575">Master</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Tambah Register Penjualan
      </Typography>
      <Divider sx={dividerStyle} />
      <Paper sx={contentContainer} elevation={12}>
        <Form noValidate validated={validated} onSubmit={saveRegister}>
          <Box sx={showDataContainer}>
            <Box sx={showDataWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Kode Register :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={kodeRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Nama Register <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={namaRegister}
                        required
                        onChange={(e) =>
                          setNamaRegister(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Nama harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Tempat, Tanggal Lahir <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="3">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={tempatLahirRegister}
                        required
                        onChange={(e) =>
                          setTempatLahirRegister(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Tempat harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                    <Col sm="4">
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={tanggalLahirRegister}
                        onChange={(e) => {
                          setTanggalLahirRegister(e);
                        }}
                        customInput={
                          <TextField
                            error={
                              error && tanggalLahirRegister === null && true
                            }
                            helperText={
                              error &&
                              tanggalLahirRegister === null &&
                              "Tanggal Lahir harus diisi!"
                            }
                            sx={{ width: "100%" }}
                            size="small"
                          />
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      No. KTP <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={noKtpRegister}
                        required
                        onChange={(e) =>
                          setNoKtpRegister(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        No. KTP harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Alamat KTP <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={almKtpRegister}
                        required
                        onChange={(e) =>
                          setAlmKtpRegister(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Alamat KTP harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      No. KK <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={noKKRegister}
                        required
                        onChange={(e) =>
                          setNoKKRegister(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        No. KK harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Status <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="7">
                      <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={statusOptions}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            error={error && statusRegister.length === 0 && true}
                            helperText={
                              error &&
                              statusRegister.length === 0 &&
                              "Status harus diisi!"
                            }
                            {...params}
                          />
                        )}
                        onInputChange={(e, value) => setStatusRegister(value)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Alamat Register <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={almRegister}
                        required
                        onChange={(e) =>
                          setAlmRegister(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Alamat harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Status Rumah <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={statusRumahRegister}
                        required
                        onChange={(e) =>
                          setStatusRumahRegister(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Status Rumah harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Lama Tinggal <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="3">
                      <Form.Control
                        value={lamaTinggalRegister}
                        required
                        onChange={(e) => {
                          let tempNum;
                          let isNumNan = isNaN(
                            parseInt(e.target.value.replace(/,/g, ""), 10)
                          );
                          if (isNumNan) {
                            tempNum = "";
                          } else {
                            tempNum = parseInt(
                              e.target.value.replace(/,/g, ""),
                              10
                            ).toLocaleString("en-US");
                          }
                          setLamaTinggalRegister(tempNum);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Lama Tinggal harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                    <Col sm="4">
                      <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={satuanLamaOptions}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            error={
                              error &&
                              lamaTinggalSatuanRegister.length === 0 &&
                              true
                            }
                            helperText={
                              error &&
                              lamaTinggalSatuanRegister.length === 0 &&
                              "Lama Tinggal Satuan harus diisi!"
                            }
                            {...params}
                          />
                        )}
                        onInputChange={(e, value) =>
                          setLamaTinggalSatuanRegister(value)
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Link Maps Register :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={linkMapsRegister}
                        onChange={(e) => setLinkMapsRegister(e.target.value)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Telepon Register <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={tlpRegister}
                        type="number"
                        required
                        onChange={(e) =>
                          setTlpRegister(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Telepon harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Alamat Email <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={alamatEmailRegister}
                        required
                        onChange={(e) =>
                          setAlamatEmailRegister(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Alamat Email harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[showDataWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Pekerjaan <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="7">
                      <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={pekerjaanOptions}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            error={error && pekerjaanId.length === 0 && true}
                            helperText={
                              error &&
                              pekerjaanId.length === 0 &&
                              "Kode Pekerjaan harus diisi!"
                            }
                            {...params}
                          />
                        )}
                        onInputChange={(e, value) =>
                          setPekerjaanId(value.split(" ", 1)[0])
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Lama Kerja <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="3">
                      <Form.Control
                        value={lamaKerjaRegister}
                        required
                        onChange={(e) => {
                          let tempNum;
                          let isNumNan = isNaN(
                            parseInt(e.target.value.replace(/,/g, ""), 10)
                          );
                          if (isNumNan) {
                            tempNum = "";
                          } else {
                            tempNum = parseInt(
                              e.target.value.replace(/,/g, ""),
                              10
                            ).toLocaleString("en-US");
                          }
                          setLamaKerjaRegister(tempNum);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Lama Kerja harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                    <Col sm="4">
                      <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={satuanLamaOptions}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            error={
                              error &&
                              lamaKerjaSatuanRegister.length === 0 &&
                              true
                            }
                            helperText={
                              error &&
                              lamaKerjaSatuanRegister.length === 0 &&
                              "Lama Kerja Satuan harus diisi!"
                            }
                            {...params}
                          />
                        )}
                        onInputChange={(e, value) =>
                          setLamaKerjaSatuanRegister(value)
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Pendapatan/Bulan <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={pendapatanPerBulanRegister}
                        required
                        onChange={(e) => {
                          let tempNum;
                          let isNumNan = isNaN(
                            parseInt(e.target.value.replace(/,/g, ""), 10)
                          );
                          if (isNumNan) {
                            tempNum = "";
                          } else {
                            tempNum = parseInt(
                              e.target.value.replace(/,/g, ""),
                              10
                            ).toLocaleString("en-US");
                          }
                          setPendapatanPerBulanRegister(tempNum);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Pendapatan/Bulan harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Pengajuan Kredit <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="7">
                      <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={pengajuanKreditOptions}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            error={
                              error &&
                              pengajuanKreditRegister.length === 0 &&
                              true
                            }
                            helperText={
                              error &&
                              pengajuanKreditRegister.length === 0 &&
                              "Pengajuan Kredit harus diisi!"
                            }
                            {...params}
                          />
                        )}
                        onInputChange={(e, value) =>
                          setPengajuanKreditRegister(value)
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Pinjaman <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={pinjamanRegister}
                        required
                        onChange={(e) => {
                          let tempNum;
                          let isNumNan = isNaN(
                            parseInt(e.target.value.replace(/,/g, ""), 10)
                          );
                          if (isNumNan) {
                            tempNum = "";
                          } else {
                            tempNum = parseInt(
                              e.target.value.replace(/,/g, ""),
                              10
                            ).toLocaleString("en-US");
                          }
                          setPinjamanRegister(tempNum);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Pinjaman harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Lama Kredit <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="3">
                      <Form.Control
                        value={lamaKreditRegister}
                        required
                        onChange={(e) => {
                          let tempNum;
                          let isNumNan = isNaN(
                            parseInt(e.target.value.replace(/,/g, ""), 10)
                          );
                          if (isNumNan) {
                            tempNum = "";
                          } else {
                            tempNum = parseInt(
                              e.target.value.replace(/,/g, ""),
                              10
                            ).toLocaleString("en-US");
                          }
                          setLamaKreditRegister(tempNum);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Lama Kredit harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                    <Col sm="4">
                      <Form.Control value={"BULAN"} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Merk <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={merkRegister}
                        required
                        onChange={(e) =>
                          setMerkRegister(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Merk harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Tipe <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={tipeRegister}
                        required
                        onChange={(e) =>
                          setTipeRegister(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Tipe harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Tahun <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={tahunRegister}
                        required
                        onChange={(e) =>
                          setTahunRegister(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Tahun harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Warna <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={warnaRegister}
                        required
                        onChange={(e) =>
                          setWarnaRegister(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Warna harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Nopol <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={nopolRegister}
                        required
                        onChange={(e) =>
                          setNopolRegister(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Nopol harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Keterangan :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={keteranganRegister}
                        onChange={(e) =>
                          setKeteranganRegister(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Keterangan harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
          <Box sx={spacingTop}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate("/register")}
              sx={{ marginRight: 2 }}
            >
              {"< Kembali"}
            </Button>
            <Button variant="contained" startIcon={<SaveIcon />} type="submit">
              Simpan
            </Button>
          </Box>
        </Form>
      </Paper>
      {/* <Paper sx={contentContainer} elevation={12}>
        <Form noValidate validated={validated} onSubmit={saveRegister}>
          <Box sx={showDataContainer}>
            <Box sx={showDataWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Kode Register :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={kodeRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Nama Register <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={namaRegister}
                        required
                        onChange={(e) =>
                          setNamaRegister(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Nama harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Alamat Register <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={almRegister}
                        required
                        onChange={(e) =>
                          setAlmRegister(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Alamat harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5">
                      Link Maps Register :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={linkMapsRegister}
                        onChange={(e) => setLinkMapsRegister(e.target.value)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Telepon Register <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={tlpRegister}
                        type="number"
                        required
                        onChange={(e) =>
                          setTlpRegister(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Telepon harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      No. KTP <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={noKtpRegister}
                        required
                        onChange={(e) =>
                          setNoKtpRegister(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        No. KTP harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Alamat KTP <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={almKtpRegister}
                        required
                        onChange={(e) =>
                          setAlmKtpRegister(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Alamat KTP harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      No. KK <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={noKKRegister}
                        required
                        onChange={(e) =>
                          setNoKKRegister(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        No. KK harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Nama Ibu Kandung :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={namaIbuKandungRegister}
                        onChange={(e) =>
                          setNamaIbuKandungRegister(
                            e.target.value.toUpperCase()
                          )
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Pekerjaan <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="7">
                      <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={pekerjaanOptions}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            error={error && pekerjaanId.length === 0 && true}
                            helperText={
                              error &&
                              pekerjaanId.length === 0 &&
                              "Kode Pekerjaan harus diisi!"
                            }
                            {...params}
                          />
                        )}
                        onInputChange={(e, value) =>
                          setPekerjaanId(value.split(" ", 1)[0])
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Kode Kecamatan <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="7">
                      <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={kecamatanOptions}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            error={error && kecamatanId.length === 0 && true}
                            helperText={
                              error &&
                              kecamatanId.length === 0 &&
                              "Kode Kecamatan harus diisi!"
                            }
                            {...params}
                          />
                        )}
                        onInputChange={(e, value) =>
                          setKecamatanId(value.split(" ", 1)[0])
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[showDataWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Nama Penjamin :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={namaPjmRegister}
                        onChange={(e) =>
                          setNamaPjmRegister(e.target.value.toUpperCase())
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Alamat Penjamin :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={almPjmRegister}
                        onChange={(e) =>
                          setAlmPjmRegister(e.target.value.toUpperCase())
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Telepon Penjamin :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        type="number"
                        value={tlpPjmRegister}
                        onChange={(e) =>
                          setTlpPjmRegister(e.target.value.toUpperCase())
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Hubungan Penjamin :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={hubunganRegister}
                        onChange={(e) =>
                          setHubunganRegister(e.target.value.toUpperCase())
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      No. KTP Penjamin :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={noKtpPjmRegister}
                        onChange={(e) =>
                          setNoKtpPjmRegister(e.target.value.toUpperCase())
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Pekerjaan Penjamin <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="7">
                      <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={pekerjaanOptions}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            error={
                              error && pekerjaanPenjaminId.length === 0 && true
                            }
                            helperText={
                              error &&
                              pekerjaanPenjaminId.length === 0 &&
                              "Pekerjaan Penjamin harus diisi!"
                            }
                            {...params}
                          />
                        )}
                        value={{ label: pekerjaanPenjaminId }}
                        onInputChange={(e, value) =>
                          setPekerjaanPenjaminId(value.split(" ", 1)[0])
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Nama Referensi :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={namaRefRegister}
                        onChange={(e) =>
                          setNamaRefRegister(e.target.value.toUpperCase())
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Alamat Referensi :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={almRefRegister}
                        onChange={(e) =>
                          setAlmRefRegister(e.target.value.toUpperCase())
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Telepon Referensi :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={tlpRefRegister}
                        onChange={(e) =>
                          setTlpRefRegister(e.target.value.toUpperCase())
                        }
                        type="number"
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
          <Box sx={spacingTop}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate("/register")}
              sx={{ marginRight: 2 }}
            >
              {"< Kembali"}
            </Button>
            <Button variant="contained" startIcon={<SaveIcon />} type="submit">
              Simpan
            </Button>
          </Box>
        </Form>
      </Paper> */}
      <Divider sx={spacingTop} />
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default TambahRegister;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  mt: 2,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const contentContainer = {
  p: 3,
  pt: 1,
  mt: 2,
  backgroundColor: Colors.grey100,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const colorRed = {
  color: "red",
};

import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate, formatTime } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { ShowTableRegister } from "../../../components/ShowTable";
import {
  SearchBar,
  Loader,
  ButtonModifier,
  ScrollToTop,
} from "../../../components";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import {
  Box,
  TextField,
  Typography,
  Divider,
  Pagination,
  Button,
  ButtonGroup,
  InputAdornment,
  Link,
} from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import { Col, Row, Form } from "react-bootstrap";

const TampilRegister = () => {
  const { user, setting, dispatch } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };
  const textRightSmall = {
    textAlign: screenSize >= 650 && "right",
    fontSize: "13px",
  };
  const [isFetchError, setIsFetchError] = useState(false);
  const [isRegisterExist, setIsRegisterExist] = useState(false);
  const [noRegister, setNoRegister] = useState("");
  const [tanggalRegister, setTanggalRegister] = useState("");
  const [namaRegister, setNamaRegister] = useState("");
  const [almRegister, setAlmRegister] = useState("");
  const [linkMapsRegister, setLinkMapsRegister] = useState("");
  const [tlpRegister, setTlpRegister] = useState("");
  const [noKtpRegister, setNoKtpRegister] = useState("");
  const [almKtpRegister, setAlmKtpRegister] = useState("");
  const [noKKRegister, setNoKKRegister] = useState("");
  const [namaIbuKandungRegister, setNamaIbuKandungRegister] = useState("");
  const [pkjRegister, setPkjRegister] = useState("");
  const [namaPjmRegister, setNamaPjmRegister] = useState("");
  const [almPjmRegister, setAlmPjmRegister] = useState("");
  const [tlpPjmRegister, setTlpPjmRegister] = useState("");
  const [hubunganRegister, setHubunganRegister] = useState("");
  const [noKtpPjmRegister, setNoKtpPjmRegister] = useState("");
  const [pkjPjmRegister, setPkjPjmRegister] = useState("");
  const [namaRefRegister, setNamaRefRegister] = useState("");
  const [almRefRegister, setAlmRefRegister] = useState("");
  const [tlpRefRegister, setTlpRefRegister] = useState("");
  const [kecamatanId, setKecamatanId] = useState("");

  // Data Format Baru
  const [tempatLahirRegister, setTempatLahirRegister] = useState("");
  const [tanggalLahirRegister, setTanggalLahirRegister] = useState("");
  const [statusRegister, setStatusRegister] = useState("");
  const [statusRumahRegister, setStatusRumahRegister] = useState("");
  const [lamaTinggalSatuanRegister, setLamaTinggalSatuanRegister] =
    useState("");
  const [lamaTinggalRegister, setLamaTinggalRegister] = useState("");
  const [alamatEmailRegister, setAlamatEmailRegister] = useState("");
  const [lamaKerjaSatuanRegister, setLamaKerjaSatuanRegister] = useState("");
  const [lamaKerjaRegister, setLamaKerjaRegister] = useState("");
  const [pendapatanPerBulanRegister, setPendapatanPerBulanRegister] =
    useState("");
  const [pengajuanKreditRegister, setPengajuanKreditRegister] = useState("");
  const [pinjamanRegister, setPinjamanRegister] = useState("");
  const [lamaKreditRegister, setLamaKreditRegister] = useState("");
  const [merkRegister, setMerkRegister] = useState("");
  const [tipeRegister, setTipeRegister] = useState("");
  const [tahunRegister, setTahunRegister] = useState("");
  const [warnaRegister, setWarnaRegister] = useState("");
  const [nopolRegister, setNopolRegister] = useState("");
  const [keteranganRegister, setKeteranganRegister] = useState("");

  const [noJual, setNoJual] = useState("");
  const [isReject, setIsReject] = useState("");

  const [searchTerm, setSearchTerm] = useState("");
  const [registersData, setRegistersData] = useState([]);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTerm(query);
  };

  useEffect(() => {
    id ? getUserById() : setIsRegisterExist(false);
  }, [id]);

  useEffect(() => {
    getRegistersData();
  }, [page, searchTerm]);

  const getRegistersData = async () => {
    setLoading(true);
    try {
      if (user.tipeUser === "MAR") {
        const allRegisters = await axios.post(
          `${tempUrl}/registersKodeMarketingPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
          {
            kodeMarketing: user.kodeMarketing,
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          }
        );
        setQuery(searchTerm);
        setRegistersData(allRegisters.data.registers);
        setPage(allRegisters.data.page);
        setPages(allRegisters.data.totalPage);
        setRows(allRegisters.data.totalRows);
      } else {
        const allRegisters = await axios.post(
          `${tempUrl}/registersAllPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
          {
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          }
        );
        setQuery(searchTerm);
        setRegistersData(allRegisters.data.registers);
        setPage(allRegisters.data.page);
        setPages(allRegisters.data.totalPage);
        setRows(allRegisters.data.totalRows);
      }
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getRegistersForDoc = async (isPdf, isExcel) => {
    setLoading(true);

    if (!id) {
      const allRegisterForDoc = await axios.post(`${tempUrl}/registersForDoc`, {
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
      setLoading(false);
      if (isPdf) {
        downloadPdf(allRegisterForDoc.data);
      } else if (isExcel) {
        downloadExcel(allRegisterForDoc.data);
      }
    } else {
      const allRegisterForDoc = await axios.post(
        `${tempUrl}/registersByIdForDoc/${id}`,
        {
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        }
      );
      setLoading(false);
      if (isPdf) {
        downloadPdf(allRegisterForDoc.data);
      } else if (isExcel) {
        downloadExcel(allRegisterForDoc.data);
      }
    }
  };

  const getUserById = async () => {
    setLoading(true);
    if (id) {
      const pickedRegister = await axios.post(`${tempUrl}/registers/${id}`, {
        id: user._id,
        token: user.token,
      });
      setIsRegisterExist(true);
      // setNoRegister(pickedRegister.data.noRegister);
      // setTanggalRegister(pickedRegister.data.tanggalRegister);
      // setNamaRegister(pickedRegister.data.namaRegister);
      // setAlmRegister(pickedRegister.data.almRegister);
      // setLinkMapsRegister(pickedRegister.data.linkMapsRegister);
      // setTlpRegister(pickedRegister.data.tlpRegister);
      // setNoKtpRegister(pickedRegister.data.noKtpRegister);
      // setAlmKtpRegister(pickedRegister.data.almKtpRegister);
      // setNoKKRegister(pickedRegister.data.noKKRegister);
      // setNamaIbuKandungRegister(pickedRegister.data.namaIbuKandungRegister);
      // setNamaPjmRegister(pickedRegister.data.namaPjmRegister);
      // setAlmPjmRegister(pickedRegister.data.almPjmRegister);
      // setTlpPjmRegister(pickedRegister.data.tlpPjmRegister);
      // setHubunganRegister(pickedRegister.data.hubunganRegister);
      // setNoKtpPjmRegister(pickedRegister.data.noKtpPjmRegister);
      // setPkjPjmRegister(
      //   `${pickedRegister.data.pekerjaanPenjaminId.kodePekerjaan} - ${pickedRegister.data.pekerjaanPenjaminId.namaPekerjaan}`
      // );
      // setPkjRegister(
      //   `${pickedRegister.data.pekerjaanId.kodePekerjaan} - ${pickedRegister.data.pekerjaanId.namaPekerjaan}`
      // );
      // setNamaRefRegister(pickedRegister.data.namaRefRegister);
      // setAlmRefRegister(pickedRegister.data.almRefRegister);
      // setTlpRefRegister(pickedRegister.data.tlpRefRegister);
      // setKecamatanId(
      //   `${pickedRegister.data.kecamatanId.kodeKecamatan} - ${pickedRegister.data.kecamatanId.namaKecamatan}`
      // );

      setNoRegister(pickedRegister.data.noRegister);
      setNamaRegister(pickedRegister.data.namaRegister);
      setNoKtpRegister(pickedRegister.data.noKtpRegister);
      setAlmKtpRegister(pickedRegister.data.almKtpRegister);
      setNoKKRegister(pickedRegister.data.noKKRegister);
      setAlmRegister(pickedRegister.data.almRegister);
      setLinkMapsRegister(pickedRegister.data.linkMapsRegister);
      setTlpRegister(pickedRegister.data.tlpRegister);
      setPkjRegister(
        `${pickedRegister.data.pekerjaanId.kodePekerjaan} - ${pickedRegister.data.pekerjaanId.namaPekerjaan}`
      );
      setTlpRegister(pickedRegister.data.tlpRegister);

      // Data Format Baru
      setTempatLahirRegister(pickedRegister.data.tempatLahirRegister);
      setTanggalLahirRegister(
        formatDate(pickedRegister.data.tanggalLahirRegister)
      );
      setStatusRegister(pickedRegister.data.statusRegister);
      setStatusRumahRegister(pickedRegister.data.statusRumahRegister);
      setLamaTinggalSatuanRegister(
        pickedRegister.data.lamaTinggalSatuanRegister
      );
      setLamaTinggalRegister(pickedRegister.data.lamaTinggalRegister);
      setAlamatEmailRegister(pickedRegister.data.alamatEmailRegister);
      setLamaKerjaSatuanRegister(pickedRegister.data.lamaKerjaSatuanRegister);
      setLamaKerjaRegister(pickedRegister.data.lamaKerjaRegister);
      setPendapatanPerBulanRegister(
        pickedRegister.data.pendapatanPerBulanRegister
      );
      setPengajuanKreditRegister(pickedRegister.data.pengajuanKreditRegister);
      setPinjamanRegister(pickedRegister.data.pinjamanRegister);
      setLamaKreditRegister(pickedRegister.data.lamaKreditRegister);
      setMerkRegister(pickedRegister.data.merkRegister);
      setTipeRegister(pickedRegister.data.tipeRegister);
      setTahunRegister(pickedRegister.data.tahunRegister);
      setWarnaRegister(pickedRegister.data.warnaRegister);
      setNopolRegister(pickedRegister.data.nopolRegister);
      setKeteranganRegister(pickedRegister.data.keteranganRegister);

      if (pickedRegister.data.isJual === true) {
        const pickedJual = await axios.post(`${tempUrl}/jualByNoRegister`, {
          noRegister: pickedRegister.data.noRegister,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        if (pickedJual.data) {
          setNoJual(pickedJual.data.noJual);
        }
      } else {
        setNoJual("");
      }
      setIsReject(pickedRegister.data.isReject === true ? "Reject" : "Tidak");
    }
    setLoading(false);
  };

  const deleteRegister = async (id) => {
    try {
      setLoading(true);
      await axios.post(`${tempUrl}/deleteRegister/${id}`, {
        id: user._id,
        token: user.token,
      });
      // setNoRegister("");
      // setTanggalRegister("");
      // setNamaRegister("");
      // setAlmRegister("");
      // setLinkMapsRegister("");
      // setTlpRegister("");
      // setNoKtpRegister("");
      // setAlmKtpRegister("");
      // setNoKKRegister("");
      // setNamaIbuKandungRegister("");
      // setPkjRegister("");
      // setNamaPjmRegister("");
      // setAlmPjmRegister("");
      // setTlpPjmRegister("");
      // setHubunganRegister("");
      // setNoKtpPjmRegister("");
      // setPkjPjmRegister("");
      // setNamaRefRegister("");
      // setAlmRefRegister("");
      // setTlpRefRegister("");

      setNoRegister("");
      setNamaRegister("");
      setNoKtpRegister("");
      setAlmRegister("");
      setNoKKRegister("");
      setLinkMapsRegister("");
      setTlpRegister("");
      setPkjRegister("");
      setTlpRegister("");

      // Data Format Baru
      setTempatLahirRegister("");
      setTanggalLahirRegister("");
      setStatusRegister("");
      setStatusRumahRegister("");
      setLamaTinggalSatuanRegister("");
      setLamaTinggalRegister("");
      setAlamatEmailRegister("");
      setLamaKerjaSatuanRegister("");
      setLamaKerjaRegister("");
      setPendapatanPerBulanRegister("");
      setPengajuanKreditRegister("");
      setPinjamanRegister("");
      setLamaKreditRegister("");
      setMerkRegister("");
      setTipeRegister("");
      setTahunRegister("");
      setWarnaRegister("");
      setNopolRegister("");
      setKeteranganRegister("");

      setLoading(false);
      navigate("/register");
      getRegistersData();
    } catch (error) {
      console.log(error);
    }
  };

  const downloadPdf = (data) => {
    var date = new Date();
    var current_date = formatDate(date);
    var current_time = formatTime(date);
    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text(`${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`, 15, 10);
    doc.text(`${setting.lokasiPerusahaan}`, 15, 15);
    doc.setFontSize(16);
    doc.text(`Daftar Register`, 90, 30);
    doc.setFontSize(10);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      290
    );

    let hal = 1;
    let tempY = 35;

    doc.setFontSize(8);

    for (let i = 0; i < data.length; i++) {
      if (tempY > 270) {
        doc.addPage();
        tempY = 10;
        hal++;
        doc.text(`Hal: ${hal}`, 220, 5);
        doc.text(
          `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
          10,
          290
        );
      }

      doc.text(`Nama Register`, 15, tempY);
      doc.text(`: ${data[i].namaRegister}`, 55, tempY);
      tempY += 5;

      if (tempY > 270) {
        doc.addPage();
        tempY = 10;
        hal++;
        doc.text(`Hal: ${hal}`, 220, 5);
        doc.text(
          `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
          10,
          290
        );
      }

      doc.text(`Tempat, Tanggal Lahir`, 15, tempY);
      doc.text(
        `: ${data[i].tempatLahirRegister}, ${data[i].tanggalLahirRegister}`,
        55,
        tempY
      );
      tempY += 5;

      if (tempY > 270) {
        doc.addPage();
        tempY = 10;
        hal++;
        doc.text(`Hal: ${hal}`, 220, 5);
        doc.text(
          `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
          10,
          290
        );
      }

      doc.text(`No. KTP`, 15, tempY);
      doc.text(`: ${data[i].noKtpRegister}`, 55, tempY);
      tempY += 5;

      if (tempY > 270) {
        doc.addPage();
        tempY = 10;
        hal++;
        doc.text(`Hal: ${hal}`, 220, 5);
        doc.text(
          `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
          10,
          290
        );
      }

      doc.text(`Alamat KTP`, 15, tempY);
      doc.text(`: ${data[i].almKtpRegister}`, 55, tempY);
      tempY += 5;

      if (tempY > 270) {
        doc.addPage();
        tempY = 10;
        hal++;
        doc.text(`Hal: ${hal}`, 220, 5);
        doc.text(
          `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
          10,
          290
        );
      }

      doc.text(`No. KK`, 15, tempY);
      doc.text(`: ${data[i].noKKRegister}`, 55, tempY);
      tempY += 5;

      if (tempY > 270) {
        doc.addPage();
        tempY = 10;
        hal++;
        doc.text(`Hal: ${hal}`, 220, 5);
        doc.text(
          `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
          10,
          290
        );
      }

      doc.text(`Status`, 15, tempY);
      doc.text(`: ${data[i].statusRegister}`, 55, tempY);
      tempY += 5;

      if (tempY > 270) {
        doc.addPage();
        tempY = 10;
        hal++;
        doc.text(`Hal: ${hal}`, 220, 5);
        doc.text(
          `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
          10,
          290
        );
      }

      doc.text(`Alamat Register`, 15, tempY);
      doc.text(`: ${data[i].almRegister}`, 55, tempY);
      tempY += 5;

      if (tempY > 270) {
        doc.addPage();
        tempY = 10;
        hal++;
        doc.text(`Hal: ${hal}`, 220, 5);
        doc.text(
          `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
          10,
          290
        );
      }

      doc.text(`Status Rumah`, 15, tempY);
      doc.text(`: ${data[i].statusRumahRegister}`, 55, tempY);
      tempY += 5;

      if (tempY > 270) {
        doc.addPage();
        tempY = 10;
        hal++;
        doc.text(`Hal: ${hal}`, 220, 5);
        doc.text(
          `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
          10,
          290
        );
      }

      doc.text(`Lama Tinggal`, 15, tempY);
      doc.text(
        `: ${data[i].lamaTinggalRegister} ${data[i].lamaTinggalSatuanRegister}`,
        55,
        tempY
      );
      tempY += 5;

      if (tempY > 270) {
        doc.addPage();
        tempY = 10;
        hal++;
        doc.text(`Hal: ${hal}`, 220, 5);
        doc.text(
          `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
          10,
          290
        );
      }

      doc.text(`Link Maps Register`, 15, tempY);
      doc.text(`: ${data[i].linkMapsRegister}`, 55, tempY);
      tempY += 5;

      if (tempY > 270) {
        doc.addPage();
        tempY = 10;
        hal++;
        doc.text(`Hal: ${hal}`, 220, 5);
        doc.text(
          `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
          10,
          290
        );
      }

      doc.text(`Telepon Register`, 15, tempY);
      doc.text(`: ${data[i].tlpRegister}`, 55, tempY);
      tempY += 5;

      if (tempY > 270) {
        doc.addPage();
        tempY = 10;
        hal++;
        doc.text(`Hal: ${hal}`, 220, 5);
        doc.text(
          `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
          10,
          290
        );
      }

      doc.text(`Alamat Email`, 15, tempY);
      doc.text(`: ${data[i].alamatEmailRegister}`, 55, tempY);
      tempY += 5;

      if (tempY > 270) {
        doc.addPage();
        tempY = 10;
        hal++;
        doc.text(`Hal: ${hal}`, 220, 5);
        doc.text(
          `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
          10,
          290
        );
      }

      doc.text(`Pekerjaan`, 15, tempY);
      doc.text(
        `: ${data[i].pekerjaanId.kodePekerjaan} - ${data[i].pekerjaanId.namaPekerjaan}`,
        55,
        tempY
      );
      tempY += 5;

      if (tempY > 270) {
        doc.addPage();
        tempY = 10;
        hal++;
        doc.text(`Hal: ${hal}`, 220, 5);
        doc.text(
          `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
          10,
          290
        );
      }

      doc.text(`Lama Kerja`, 15, tempY);
      doc.text(
        `: ${data[i].lamaKerjaRegister} ${data[i].lamaKerjaSatuanRegister}`,
        55,
        tempY
      );
      tempY += 5;

      if (tempY > 270) {
        doc.addPage();
        tempY = 10;
        hal++;
        doc.text(`Hal: ${hal}`, 220, 5);
        doc.text(
          `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
          10,
          290
        );
      }

      doc.text(`Pendapatan/Bulan`, 15, tempY);
      doc.text(
        `: ${data[i].pendapatanPerBulanRegister.toLocaleString("en-US")}`,
        55,
        tempY
      );
      tempY += 5;

      if (tempY > 270) {
        doc.addPage();
        tempY = 10;
        hal++;
        doc.text(`Hal: ${hal}`, 220, 5);
        doc.text(
          `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
          10,
          290
        );
      }

      doc.text(`Pengajuan Kredit`, 15, tempY);
      doc.text(`: ${data[i].pengajuanKreditRegister}`, 55, tempY);
      tempY += 5;

      if (tempY > 270) {
        doc.addPage();
        tempY = 10;
        hal++;
        doc.text(`Hal: ${hal}`, 220, 5);
        doc.text(
          `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
          10,
          290
        );
      }

      doc.text(`Pinjaman`, 15, tempY);
      doc.text(
        `: ${data[i].pinjamanRegister.toLocaleString("en-US")}`,
        55,
        tempY
      );
      tempY += 5;

      if (tempY > 270) {
        doc.addPage();
        tempY = 10;
        hal++;
        doc.text(`Hal: ${hal}`, 220, 5);
        doc.text(
          `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
          10,
          290
        );
      }

      doc.text(`Lama Kredit`, 15, tempY);
      doc.text(`: ${data[i].lamaKreditRegister} BULAN`, 55, tempY);
      tempY += 5;

      if (tempY > 270) {
        doc.addPage();
        tempY = 10;
        hal++;
        doc.text(`Hal: ${hal}`, 220, 5);
        doc.text(
          `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
          10,
          290
        );
      }

      doc.text(`Merk`, 15, tempY);
      doc.text(`: ${data[i].merkRegister}`, 55, tempY);
      tempY += 5;

      if (tempY > 270) {
        doc.addPage();
        tempY = 10;
        hal++;
        doc.text(`Hal: ${hal}`, 220, 5);
        doc.text(
          `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
          10,
          290
        );
      }

      doc.text(`Tipe`, 15, tempY);
      doc.text(`: ${data[i].tipeRegister}`, 55, tempY);
      tempY += 5;

      if (tempY > 270) {
        doc.addPage();
        tempY = 10;
        hal++;
        doc.text(`Hal: ${hal}`, 220, 5);
        doc.text(
          `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
          10,
          290
        );
      }

      doc.text(`Tahun`, 15, tempY);
      doc.text(`: ${data[i].tahunRegister}`, 55, tempY);
      tempY += 5;

      if (tempY > 270) {
        doc.addPage();
        tempY = 10;
        hal++;
        doc.text(`Hal: ${hal}`, 220, 5);
        doc.text(
          `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
          10,
          290
        );
      }

      doc.text(`Warna`, 15, tempY);
      doc.text(`: ${data[i].warnaRegister}`, 55, tempY);
      tempY += 5;

      if (tempY > 270) {
        doc.addPage();
        tempY = 10;
        hal++;
        doc.text(`Hal: ${hal}`, 220, 5);
        doc.text(
          `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
          10,
          290
        );
      }

      doc.text(`Nopol`, 15, tempY);
      doc.text(`: ${data[i].nopolRegister}`, 55, tempY);
      tempY += 5;

      if (tempY > 270) {
        doc.addPage();
        tempY = 10;
        hal++;
        doc.text(`Hal: ${hal}`, 220, 5);
        doc.text(
          `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
          10,
          290
        );
      }

      doc.text(`Keterangan`, 15, tempY);
      doc.text(`: ${data[i].keteranganRegister}`, 55, tempY);

      tempY += 2.5;
      doc.text(
        `---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------`,
        10,
        tempY
      );
      tempY += 2.5;
    }
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  const downloadExcel = (data) => {
    const workSheet = XLSX.utils.json_to_sheet(data);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, `Register`);
    // Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    // Binary String
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    // Download
    XLSX.writeFile(workBook, `daftarRegister.xlsx`);
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Box>
      <ScrollToTop />
      <Typography color="#757575">Penjualan</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Register Penjualan
      </Typography>

      {isRegisterExist && (
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => navigate("/register")}
          sx={{ marginLeft: 2, marginTop: 4 }}
        >
          {"< Kembali"}
        </Button>
      )}

      <Box sx={downloadButtons}>
        <ButtonGroup variant="outlined" color="secondary">
          <Button
            startIcon={<PrintIcon />}
            onClick={() => getRegistersForDoc(true, false)}
          >
            CETAK
          </Button>
          <Button
            startIcon={<DownloadIcon />}
            onClick={() => getRegistersForDoc(false, true)}
          >
            EXCEL
          </Button>
        </ButtonGroup>
      </Box>
      <Box sx={buttonModifierContainer}>
        <ButtonModifier
          id={id}
          kode={isRegisterExist}
          addLink={`/register/tambahRegister`}
          editLink={`/register/${id}/edit`}
          deleteUser={deleteRegister}
          nameUser={noRegister}
        />
      </Box>
      {isRegisterExist && (
        <>
          <Divider sx={dividerStyle} />
          <Box sx={showDataContainer}>
            <Box sx={showDataWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      No :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={noRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Nama Register :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={namaRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Tempat, Tanggal Lahir :
                    </Form.Label>
                    <Col sm="3">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={tempatLahirRegister}
                        disabled
                      />
                    </Col>
                    <Col sm="4">
                      <Form.Control value={tanggalLahirRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      No. KTP :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={noKtpRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Alamat KTP :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={almKtpRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      No. KK :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={noKKRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Status :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={statusRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Alamat Register :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={almRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Status Rumah :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={statusRumahRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Lama Tinggal :
                    </Form.Label>
                    <Col sm="3">
                      <Form.Control value={lamaTinggalRegister} disabled />
                    </Col>
                    <Col sm="4">
                      <Form.Control
                        value={lamaTinggalSatuanRegister}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Link Maps Register :
                    </Form.Label>
                    <Col sm="7">
                      <TextField
                        disabled
                        value={linkMapsRegister}
                        defaultValue={linkMapsRegister}
                        variant="outlined"
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Link href={linkMapsRegister}>Buka Link</Link>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Telepon Register :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={tlpRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Alamat Email :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={alamatEmailRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[showDataWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Pekerjaan :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={pkjRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Lama Kerja :
                    </Form.Label>
                    <Col sm="3">
                      <Form.Control value={lamaKerjaRegister} disabled />
                    </Col>
                    <Col sm="4">
                      <Form.Control value={lamaKerjaSatuanRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Pendapatan/Bulan :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={pendapatanPerBulanRegister.toLocaleString(
                          "en-US"
                        )}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Pengajuan Kredit :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={pengajuanKreditRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Pinjaman :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={pinjamanRegister.toLocaleString("en-US")}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Lama Kredit :
                    </Form.Label>
                    <Col sm="3">
                      <Form.Control value={lamaKreditRegister} disabled />
                    </Col>
                    <Col sm="4">
                      <Form.Control value={"BULAN"} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Merk :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={merkRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Tipe :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={tipeRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Tahun :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={tahunRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Warna :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={warnaRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Nopol :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={nopolRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Keterangan :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={keteranganRegister}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
          {/* <Box sx={showDataContainer}>
            <Box sx={showDataWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      No :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={noRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Tanggal :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={tanggalRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Nama Register :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={namaRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Alamat Register :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={almRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Link Maps Register :
                    </Form.Label>
                    <Col sm="7">
                      <TextField
                        disabled
                        value={linkMapsRegister}
                        defaultValue={linkMapsRegister}
                        variant="outlined"
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Link href={linkMapsRegister}>Buka Link</Link>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Telepon Register :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={tlpRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      No. KTP Register :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={noKtpRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Alamat KTP Register :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={almKtpRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      No. KK Register :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={noKKRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Nama Ibu Kandung :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={namaIbuKandungRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Pekerjaan Register :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={pkjRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      No. Jual :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={noJual} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Reject :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={isReject} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[showDataWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Kecamatan :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={kecamatanId} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Nama Penjamin :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={namaPjmRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Alamat Penjamin :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={almPjmRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Telepon Penjamin :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={tlpPjmRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Hubungan Penjamin :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={hubunganRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      No. KTP Penjamin :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={noKtpPjmRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Pekerjaan Penjamin :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={pkjPjmRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Nama Referensi :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={namaRefRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Alamat Referensi :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={almRefRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Telepon Referensi :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={tlpRefRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box> */}
        </>
      )}
      <Divider sx={dividerStyle} />
      <Form onSubmit={searchData}>
        <Box sx={searchBarContainer}>
          <SearchBar value={query} setSearchTerm={setQuery} />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disableElevation
          >
            Cari
          </Button>
        </Box>
      </Form>
      <Box sx={tableContainer}>
        <ShowTableRegister currentPosts={registersData} />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={pages}
          page={page + 1}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
    </Box>
  );
};

export default TampilRegister;

const subTitleText = {
  fontWeight: "900",
};

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const dividerStyle = {
  pt: 4,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const spacingTop = {
  mt: 4,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const downloadButtons = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};
